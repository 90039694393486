import moment from 'moment';
import { I18n } from 'react-redux-i18n';

const utcOffset = moment().utcOffset();
export const convertToUTC = (m, format = I18n.t('datetime_format.utc')) =>
    m ? moment(m).add(-utcOffset, 'minutes').format(format) + 'Z' : '';
export const formatTime = (m, format = I18n.t('datetime_format.long')) => m ? moment(m).format(format) : '';

export const getPassNDays = (days = 0, isEndOfDay = false) => {
    let result = moment().add(-days, 'day');
    if (isEndOfDay) {
        result = result.endOf('day');
    }
    else {
        result = result.startOf('day');
    }
    return result;
};