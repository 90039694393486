import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageItem from './PageItem';

export const PREV_PAGE_WINDOW = 'PREV_PAGE_WINDOW';
export const PREV_PAGE = 'PREV_PAGE';
export const NEXT_PAGE = 'NEXT_PAGE';
export const NEXT_PAGE_WINDOW = 'NEXT_PAGE_WINDOW';

const NavPageItem = ({ total, pagePerWin, itemClass, page, type, children, onClick }) => {
    let disabled = true;
    let goToPage = page;
    let customClassName;

    switch (type) {
    case PREV_PAGE_WINDOW:
        // in first page window
        disabled = page <= pagePerWin && page <= 1;
        goToPage = (page - pagePerWin > 0 ? page - pagePerWin : 1);
        customClassName = classNames({
            'prev-page-win': true,
            'disabled': disabled,
            [itemClass]: itemClass,
        });
        break;
    case PREV_PAGE:
        // in first page
        disabled = page === 1;
        goToPage = page - 1;
        customClassName = classNames({
            'prev-page': true,
            'disabled': disabled,
            [itemClass]: itemClass,
        });
        break;
    case NEXT_PAGE:
        // in last page
        disabled = page === total;
        goToPage = page + 1;
        customClassName = classNames({
            'next-page': true,
            'disabled': disabled,
            [itemClass]: itemClass,
        });
        break;
    case NEXT_PAGE_WINDOW:
        // in last page window
        disabled = (page >= total - pagePerWin + 1) && page >= total;
        goToPage = (page + pagePerWin > total ? total : page + pagePerWin);
        customClassName = classNames({
            'next-page-win': true,
            'disabled': disabled,
            [itemClass]: itemClass,
        });
        break;
    default:
        break;
    }
    return (
        <PageItem className={ customClassName } onClick={ disabled ? null : () => onClick(goToPage) }>
            { children }
        </PageItem>
    );
};

NavPageItem.propTypes = {
    pagePerWin: PropTypes.number.isRequired,
    itemClass: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default NavPageItem;