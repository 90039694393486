import { FETCH_GROUP, CLEAR_GROUP } from '../constants/action-type';

const INITIAL_STATE = {
    groupList: [],
    pageTotal: undefined,
};


const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case FETCH_GROUP:
        return {
            ...state,
            groupList: action.data.corporate_groups,
            pageTotal: action.data.corporate_groups.length,
        };
    case CLEAR_GROUP:
        return {
            ...state,
            groupList: [],
            pageTotal: undefined,
        };
    default:
        return state;
    }
};
export default reducer;
