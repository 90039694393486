import React from 'react';
import PropTypes from 'prop-types';
import PageItem from './PageItem';
import classNames from 'classnames';
import NavPageItem, {
    PREV_PAGE_WINDOW,
    PREV_PAGE,
    NEXT_PAGE,
    NEXT_PAGE_WINDOW,
} from './NavPageItem';
import './pagination.scss';

class Pagination extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        itemClass: PropTypes.string,
        // current page
        page: PropTypes.number,
        // total pages
        total: PropTypes.number,
        // pages per window. eg size=5, the first page window would be [1,2,3,4,5]
        size: PropTypes.number,
        onSelect: PropTypes.func,
        hidePageControl: PropTypes.arrayOf(PropTypes.bool),
        center: PropTypes.bool,
    };

    static defaultProps = {
        page: 0,
        total: 0,
        size: 6,
        className: '',
        itemClass: '',
        onSelect: () => { },
        hidePageControl: [false, true, true, false],
        center: true,
    };

    createPageWindow = (page, total, pagePerWin) => {
        let expansion = pagePerWin / 2;
        let win = []; let min = 0; let max = -1;
        // 0 pages
        if (page <= 0 || total <= 0 || pagePerWin <= 0) {
            min = 0;
            max = -1;
        }
        // in first page window
        else if (page < 1 + expansion) {
            min = 1;
            max = pagePerWin;
        }
        // in last page window
        else if (page + expansion > total) {
            min = total - pagePerWin + 1;
            max = total;
        }
        // in other page window
        else {
            min = page - Math.ceil(expansion) + 1;
            max = page + Math.floor(expansion);
        }
        for (let i = min; i <= max; i++) {
            win.push(i);
        }
        return win;
    };

    render() {
        const { page, total, size, center, onSelect, className, itemClass, hidePageControl } = this.props;
        const pagePerWin = total < size ? total : size;
        const pageWindow = this.createPageWindow(page, total, pagePerWin);
        const customClass = classNames({
            'pagination': true,
            'justify-content-center': center,
            [className]: className,
        });
        const Pages = ({ selected, itemClass, window, onSelect }) => window.map((page, i) => {
            return (
                <PageItem
                    key={ `page-${page}` }
                    className={ classNames({
                        'active': selected === page,
                        [itemClass]: itemClass,
                    }) }
                    onClick={ () => onSelect(page) }
                >
                    <span className="page-link num-page">{ page }</span>
                </PageItem>
            );
        });


        let pagination = null;
        if (page > 0 && total > 1 && size > 1) {
            pagination = (
                <nav className="pagination-wrapper">
                    <ul className={ customClass }>
                        {
                            !hidePageControl[0] ?
                                (
                                    <NavPageItem
                                        type={ PREV_PAGE_WINDOW }
                                        onClick={ onSelect }
                                        itemClass={ itemClass }
                                        pagePerWin={ pagePerWin }
                                        page={ page }
                                        total={ total }
                                    >
                                        <span className="page-link icon fas fa-angle-double-left" />
                                    </NavPageItem>
                                ) : null
                        }
                        {
                            !hidePageControl[1] ?
                                (
                                    <NavPageItem
                                        type={ PREV_PAGE }
                                        onClick={ onSelect }
                                        itemClass={ itemClass }
                                        pagePerWin={ pagePerWin }
                                        page={ page }
                                        total={ total }
                                    >
                                        <span className="page-link icon fas fa-angle-left" />
                                    </NavPageItem>
                                ) : null
                        }
                        <Pages selected={ page } window={ pageWindow } onSelect={ onSelect } itemClass={ itemClass } />
                        {
                            !hidePageControl[2] ?
                                (
                                    <NavPageItem
                                        type={ NEXT_PAGE }
                                        onClick={ onSelect }
                                        itemClass={ itemClass }
                                        pagePerWin={ pagePerWin }
                                        page={ page }
                                        total={ total }
                                    >
                                        <span className="page-link icon fas fa-angle-right" />
                                    </NavPageItem>
                                ) : null
                        }
                        {
                            !hidePageControl[3] ?
                                (
                                    <NavPageItem
                                        type={ NEXT_PAGE_WINDOW }
                                        onClick={ onSelect }
                                        itemClass={ itemClass }
                                        pagePerWin={ pagePerWin }
                                        page={ page }
                                        total={ total }
                                    >
                                        <span className="page-link icon fas fa-angle-double-right" />
                                    </NavPageItem>
                                ) : null
                        }
                    </ul>
                </nav>
            );
        }
        return pagination;
    }
}

export default Pagination;
