import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-redux-i18n';
import serialize from 'form-serialize';
import Modal from '../../../components/Modal';
import ButtonGroup from '../../../components/ButtonGroup';
import Input from '../../../components/Form/Input';
import Button from '../../../components/Form/Button';
import RingSpinner from '../../../components/Spinner/Ring';
import Blocker from '../../../components/Blocker';
import { createGroup, patchGroup } from '../../../actions';

import './group-action.scss';
import { useSelector, useDispatch } from 'react-redux';

const GroupAction = ({ onClose, onAction, groupInfo, innerRef }) => {
    const dispatch = useDispatch();

    const [name, setName] = useState(groupInfo ? groupInfo.name : '');
    const [description, setDescription] = useState(groupInfo ? groupInfo.description : '');

    const { asyncStart } = useSelector(state => state.asyncHandler);

    useEffect(() => {
        if (innerRef.current) {
            innerRef.current.focus();
        }
    }, [innerRef]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formData = serialize(e.currentTarget, { hash: true });
        const { groupName, groupDescription } = formData;
        let submitObject = {
            name: groupName,
            description: groupDescription ? groupDescription : '',
        };
        if (groupInfo !== null) {
            submitObject = {
                ...submitObject,
                id: groupInfo.id
            };
            await dispatch(patchGroup(groupInfo.id, submitObject));
        }
        else {
            await dispatch(createGroup(submitObject));
        }
        onAction();
        onClose();
    };

    const renderSpinner = () => {
        const spinnerStyle = classNames({
            'spinner-container': true,
            hide: !asyncStart,
        });
        const Spinner = () => (
            <Blocker className={ spinnerStyle }>
                <RingSpinner />
            </Blocker>
        );
        return <Spinner />;
    };

    return (
        <Modal
            className="group-action"
            title={ groupInfo ? I18n.t('group.edit') : I18n.t('group.create') }
            onClose={ onClose }
        >
            <form className="form section" onSubmit={ handleSubmit }>
                <Input
                    name="groupName"
                    innerRef={ innerRef }
                    caption="group.group"
                    value={ name }
                    onChange={ (e) => {
                        setName(e);
                    } }
                    autoComplete="off"
                    required
                />
                <Input
                    name="groupDescription"
                    caption="group.description"
                    value={ description }
                    onChange={ (e) => {
                        setDescription(e);
                    } }
                    autoComplete="off"
                />
                <ButtonGroup className="modal-button-group">
                    <Button
                        type="button"
                        onClick={ onClose }
                    >
                        <Translate value="cancel" />
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                    >
                        <Translate value="save" />
                    </Button>
                </ButtonGroup>
            </form>
            { renderSpinner() }
        </Modal>
    );
};

GroupAction.propTypes = {
    groupInfo: PropTypes.shape(),
    innerRef: PropTypes.shape(),
    onClose: PropTypes.func,
    onAction: PropTypes.func,
};

GroupAction.defaultProps = {
    groupInfo: null,
    innerRef: undefined,
    onClose: () => {},
    onAction: () => {},
};

export default GroupAction;