import React from 'react';
import PropTypes from 'prop-types';
import { Label, Input } from 'reactstrap';

import './choice.scss';

const Component = ({ defaultChecked, options, onChange }) => (
    <div className="choice-list">
        {
            options.map(({ id, name, value }) => (
                <Label key={ id } className="choice-type">
                    <Input
                        type="radio"
                        name="choice-type"
                        value={ value }
                        defaultChecked={ id === defaultChecked }
                        onChange={ onChange }
                    />
                    <span className="choice-type-text">{ name }</span>
                </Label>
            ))
        }
    </div>
);

Component.propTypes = {
    // `defaultChecked` has to be one of ids
    defaultChecked: PropTypes.node.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.node.isRequired,
        name: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ]).isRequired,
        value: PropTypes.node.isRequired,
    })).isRequired,
    onChange: PropTypes.func,
};

Component.defaultProps = {
    onChange: () => {},
};

export default Component;
