import React from 'react';
import PropTypes from 'prop-types';
import Cell from './Cell';
import CheckboxCell from './CheckboxCell';

const ListRow = (props) => {
    const { columns, data, dataKey, selectable, selected, onSelect } = props;
    const handleSelect = (e) => {
        onSelect([data[dataKey]]);
    };
    return (
        <React.Fragment>
            {
                selectable ? (
                    <CheckboxCell onSelect={ handleSelect } selected={ selected } />
                ) : null
            }
            {
                columns.map(({ key, width }) => {
                    return (
                        <Cell
                            key={ key?.toString() }
                            name={ key }
                            style={ { width } }
                        >
                            <span>{ data[key] }</span>
                        </Cell>
                    );
                })
            }
        </React.Fragment>
    );
};

ListRow.propTypes = {
    onSelect: PropTypes.func,
    columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    data: PropTypes.shape().isRequired,
    dataKey: PropTypes.string,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
};

ListRow.defaultProps = {
    onSelect: () => { },
    selectable: false,
    selected: false,
    dataKey: undefined,
};


export default ListRow;