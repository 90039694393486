import {
    WELCOME,
    TRIP_ACTIVITY,
    USER_MANAGEMENT,
    GROUP,
    BILLING,
} from './routes';

import {
    AUTH_VIEW_TRIP_ACTIVITY,
    AUTH_VIEW_USER_LIST,
    AUTH_VIEW_GROUP_LIST,
    AUTH_VIEW_BUSINESS_PLAN,
} from './permission';

export const GROUP_TRIP = 'group_trip';
export const GROUP_USER = 'group_user';
export const GROUP_BILLING = 'group_billing';

const routeWithHierarchy = [{
    route: WELCOME,
    isMenuItem: false,
    order: 0,
}, {
    route: TRIP_ACTIVITY,
    category: GROUP_TRIP,
    auth: AUTH_VIEW_TRIP_ACTIVITY,
    showAsCategory: true,
    isMenuItem: true,
    order: 0,
}, {
    route: USER_MANAGEMENT,
    auth: AUTH_VIEW_USER_LIST,
    category: GROUP_USER,
    showAsCategory: false,
    isMenuItem: true,
    order: 0,
}, {
    route: GROUP,
    auth: AUTH_VIEW_GROUP_LIST,
    category: GROUP_USER,
    showAsCategory: false,
    isMenuItem: true,
    order: 0,
}, {
    route: BILLING,
    auth: AUTH_VIEW_BUSINESS_PLAN,
    category: GROUP_BILLING,
    showAsCategory: true,
    isMenuItem: true,
    order: 0,
}];

export default routeWithHierarchy;