import { EXPORT_FILE, CLOSE_EXPORT } from '../constants/action-type';

const INITIAL_STATE = {
    exportFinished: false,
};


const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case EXPORT_FILE:
        return {
            ...state,
            exportFinished: true,
        };
    case CLOSE_EXPORT:
        return {
            ...state,
            exportFinished: false,
        };
    default:
        return state;
    }
};

export default reducer;
