import React from 'react';

const titleStyle = {
    fontSize: '1.5em',
    textAlign: 'center',
    color: 'palevioletred',
};

const Welcome = () => {
    return (
        <React.Fragment>
            <h1 style={ titleStyle }>Welcome</h1>
        </React.Fragment>
    );
};

export default Welcome;