import React from 'react';
import PropTypes from 'prop-types';
import permissionHandler from '../../helpers/permission-handler';

const AuthFeature = ({ requiredList, children }) => {
    return (
        <>
            {
                permissionHandler({ requiredList }) && children
            }
        </>
    );
};

AuthFeature.propTypes = {
    requiredList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AuthFeature;