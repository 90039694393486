import {
    GROUP,
} from '../../../constants/routes';

import Routes from './routes';

const lang = {
    'document_title': Routes[GROUP],
    'description': '說明',
    'count': '群組人數',
    'group': '群組名稱',
    'edit': '編輯群組',
    'create': '創建',
    'remove': '刪除群組',
    'confirm-remove': '確定刪除以下群組：',
};

export default lang;