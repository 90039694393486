import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isMobile from 'is-mobile';
import { Button } from 'reactstrap';
import FlexGroup from '../../components/FlexGroup';
import ListView from '../../components/ListView';
import ListRow from './ListRow';
import UserAction from './UserAction';
import { Translate, I18n } from 'react-redux-i18n';
import Pagination from '../../components/Pagination';
import PageSizeSelect, { getDefaultPageSize } from '../../components/Pagination/PageSizeSelect';
import DeletePanel from '../../components/DeletePanel';
import UpdateTime from '../../components/UpdateTime';
import RefreshButton from '../../components/RefreshButton';
import TotalCount from '../../components/TotalCount';
import { headerConfig } from './ListHeader';
import { patchUser } from '../../actions';
import ButtonGroup from '../../components/ButtonGroup';


const List = (props) => {
    const dispatch = useDispatch();
    const { onSelect,
            onAction,
            onChange,
            onUncheckedAll,
            selectedIdList,
            list,
            pageSize,
            pageCount,
            pageIndex,
            timer,
    } = props;

    const filterList = list.filter(({ id }) => selectedIdList.includes(id)).map(item => {
        return {
            id: item.id,
            state: item.state,
        };
    });

    const [showDeletePanel, setShowDeletePanel] = useState(false);
    const [showUserAction, setShowUserAction] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    const { pageTotal } = useSelector(state => state.user);

    const emailRef = useRef(null);
    const nameRef = useRef(null);

    const setSelectById = (ids, selectAll) => {
        let updatedUserList = [...selectedIdList];

        if (selectAll) {
            updatedUserList = ids.length === list.length ? ids : [];
        }
        else {
            ids.forEach(id => {
                if (updatedUserList.indexOf(id) === -1) {
                    updatedUserList.push(id);
                }
                else {
                    const idx = updatedUserList.indexOf(id);
                    updatedUserList.splice(idx, 1);
                }
            });
        }

        onSelect(updatedUserList);
    };

    const handleListSort = ({ field, order }) => {
        let sort = null;
        if (field && order) {
            sort = `${ field },${ order }`;
        }
        onChange({
            sort,
            page: 1,
        });
    };

    const handlePageSizeChange = selection => {
        onChange({
            size: selection,
            page: 1,
        });
    };

    const handlePageSelect = page => {
        onChange({
            page,
            size: pageSize,
        });
    };

    const toggleDeletePanel = () => {
        setShowDeletePanel(!showDeletePanel);
    };

    const toggleUserAction = () => {
        setUserInfo(null);
        setShowUserAction(!showUserAction);
    };

    const setUserAction = (data) => {
        setShowUserAction(true);
        setUserInfo(data);
    };

    const ListItem = ({ columns, rowData }) => (
        <ListRow
            key={ `user-${ rowData.id }` }
            columns={ columns }
            data={ rowData }
            onClick={ setUserAction }
        />
    );

    ListItem.propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        rowData: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    };

    const handleDeleteSubmit = async (e) => {
        e.preventDefault();
        const deleteUserList = filterList.map(el => {
            let changeselectedUserData = el;
            changeselectedUserData.state = 0;
            return changeselectedUserData;
        });
        await dispatch(patchUser(deleteUserList));
        onUncheckedAll();
        onAction({
            page: 1,
        });
        toggleDeletePanel();
    };

    return (
        <React.Fragment>
            <FlexGroup start wrap>
                <ButtonGroup start className="button-group-before-pagi">
                    <Button
                        className="user-btn"
                        color="primary"
                        type="button"
                        onClick={ () => {
                            setShowUserAction(true);
                        } }
                    >
                        <Translate value="user.new" />
                    </Button>
                    <button
                        className="btn-delete"
                        disabled={ selectedIdList.length === 0 }
                        onClick={ toggleDeletePanel }
                        type="button"
                    >
                        <span className="btn-delete-icon btn-icon" />
                    </button>
                </ButtonGroup>
                <div className="pagination-control-sec">
                    <Pagination
                        page={ pageIndex }
                        total={ pageCount }
                        offset={ pageSize }
                        onSelect={ handlePageSelect }
                    />
                    <PageSizeSelect
                        onChange={ selection => handlePageSizeChange(selection.value) }
                    />
                </div>
                <div className="pagination-after-block">
                    <TotalCount count={ pageTotal } />
                    <UpdateTime time={ timer } />
                    <RefreshButton onClick={ onAction } />
                </div>
            </FlexGroup>
            <ListView
                className="list-view-bottom"
                isInitailResult={ !!(pageTotal === undefined) }
                list={ list }
                dataKey="id"
                renderListRow={ ListItem }
                onSelect={ setSelectById }
                onSort={ handleListSort }
                defaultSelect={ selectedIdList }
                header={ headerConfig }
                selectable
                fixed={ isMobile() }
            />
            {
                showDeletePanel &&
                (
                    <DeletePanel
                        list={ list }
                        title={ I18n.t('user.remove') }
                        description="user.confirm-remove"
                        selectedIdList={ selectedIdList }
                        onSubmit={ handleDeleteSubmit }
                        onClose={ toggleDeletePanel }
                    />
                )
            }
            {
                showUserAction &&
                (
                    <UserAction
                        innerRef={ { email: emailRef, name: nameRef } }
                        userInfo={ userInfo }
                        onAction={ onAction }
                        onClose={ toggleUserAction }
                    />
                )
            }
        </React.Fragment>
    );
};

List.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({})),
    onSelect: PropTypes.func.isRequired,
    onAction: PropTypes.func,
    onUncheckedAll: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    pageIndex: PropTypes.number,
    pageCount: PropTypes.number,
    pageSize: PropTypes.number,
    selectedIdList: PropTypes.arrayOf(PropTypes.string).isRequired,
    timer: PropTypes.string,
};

List.defaultProps = {
    list: [],
    pageIndex: 0,
    pageCount: 0,
    pageSize: getDefaultPageSize().value,
    onAction: () => {},
    onUncheckedAll: () => {},
    timer: undefined,
};

export default List;