import React from 'react';
import PropTypes from 'prop-types';
import useRegion from '../../hooks/useRegion';
import FlexGroup from '../../components/FlexGroup';
import ListView from '../../components/ListView';
import ListRow from './ListRow';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Pagination from '../../components/Pagination';
import PageSizeSelect, { getDefaultPageSize } from '../../components/Pagination/PageSizeSelect';
import Period from '../../components/Period/Period';
import UpdateTime from '../../components/UpdateTime';
import RefreshButton from '../../components/RefreshButton';
import TotalCount from '../../components/TotalCount';
import { headerConfig, overseaHeaderConfig } from './ListHeader';

const List = (props) => {
    const { onChange, pageSize, list, onFetch, timer, initSearchFrom, initSearchTo } = props;
    const { page_index, page_count, data_list, total } = list.toJS();
    const { isOversea } = useRegion();

    const handlePageSizeChange = selection => {
        onChange({
            size: selection.value,
            page: 1,
        });
    };
    const handlePageChange = page => {
        onChange({
            page,
        });
    };

    const ListItem = ({ columns, rowData }) => (
        <ListRow
            key={ `trip-${ rowData.rental_id }` }
            columns={ columns }
            data={ rowData }
        />
    );

    ListItem.propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        rowData: PropTypes.shape({
            rental_id: PropTypes.string.isRequired,
        }).isRequired,
    };

    return (
        <React.Fragment>
            <FlexGroup wrap start>
                <div className="pagination-control-sec">
                    <Pagination
                        page={ page_index }
                        total={ page_count }
                        offset={ pageSize }
                        onSelect={ handlePageChange }
                    />
                    <PageSizeSelect
                        onChange={ handlePageSizeChange }
                    />
                </div>
                <div className="pagination-after-block">
                    <TotalCount count={ total } />
                    <Period fromTime={ initSearchFrom } toTime={ initSearchTo } />
                    <UpdateTime time={ timer } />
                    <RefreshButton onClick={ onFetch } />
                </div>
            </FlexGroup>
            <ListView
                className="list-view-bottom"
                isInitailResult={ !!(total === undefined) }
                list={ data_list }
                dataKey="rental_id"
                renderListRow={ ListItem }
                header={ isOversea ? overseaHeaderConfig : headerConfig }
                fixed
            />
        </React.Fragment>
    );
};

List.propTypes = {
    list: ImmutablePropTypes.map.isRequired,
    onChange: PropTypes.func.isRequired,
    pageSize: PropTypes.number,
    onFetch: PropTypes.func,
    timer: PropTypes.string,
    initSearchFrom: PropTypes.string,
    initSearchTo: PropTypes.string,
};

List.defaultProps = {
    pageSize: getDefaultPageSize().value,
    onFetch: () => {},
    timer: undefined,
    initSearchFrom: undefined,
    initSearchTo: undefined,
};


export default List;