import { ASYNC_START, ERROR, ASYNC_FINISHED } from '../../constants/action-type';

const INITIAL_STATE = {
    asyncStart: false,
    asyncError: undefined,
};


const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case ASYNC_START:
        return {
            asyncStart: true,
        };
    case ASYNC_FINISHED:
        return {
            asyncStart: false,
        };
    case ERROR:
        return {
            asyncError: action.error,
        };
    default:
        return state;
    }
};

export default reducer;