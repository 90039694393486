import { createOAuthInstance, createInstance,
    createBusinessApiInstance, createBusinessApiInstanceWithToken } from '../util';
import { ROOT } from '../../constants/routes';

const { REACT_APP_API_BASE_URL } = process.env;

const account = {
    fetchTokenByCode: code => {
        return createOAuthInstance().post(ROOT, {
            code,
        });
    },
    fetchNewToken: () => {
        return createOAuthInstance().post(ROOT, {});
    },
    fetchCompanyAuth: (accessToken = '') => {
        if ( accessToken === '') {
            return createBusinessApiInstanceWithToken().get('/business_users');
        }
        return createBusinessApiInstance({
            'Authorization': `Bearer ${ accessToken }`,
        }).get('/business_users');
    },
    deleteCookie: () => {
        const baseUrl = REACT_APP_API_BASE_URL;
        return createInstance(`${baseUrl}/business_users`).delete('/brf_cookie');
    }
};
export default account;
