export const headerConfig = [{
    name: 'start_time',
    value: 'trip.start_time',
    sortable: false,
    width: 120,
}, {
    name: 'end_time',
    value: 'trip.end_time',
    sortable: false,
    width: 120,
}, {
    name: 'group_name',
    value: 'group.group',
    sortable: false,
    width: 120,
}, {
    name: 'user_name',
    value: 'name',
    sortable: false,
    width: 140,
}, {
    name: 'user_mail',
    value: 'email',
    sortable: false,
    width: 240,
}, {
    name: 'plate_no',
    value: 'plate_no',
    sortable: false,
    width: 200,
}, {
    name: 'duration',
    value: 'duration',
    sortable: false,
    width: 120,
}, {
    name: 'distance',
    value: 'trip.distance',
    sortable: false,
    width: 120,
}, {
    name: 'subtotal',
    value: 'trip.subtotal',
    sortable: false,
    width: 150,
}, {
    name: 'allowance',
    value: 'trip.allowance',
    sortable: false,
    width: 120,
}, {
    name: 'refund_price',
    value: 'trip.refund',
    sortable: false,
    width: 90,
}, {
    name: 'price',
    value: 'charged',
    sortable: false,
    width: 100,
}, {
    name: 'payment_status',
    value: 'payment_status',
    sortable: false,
    width: 105,
}, {
    name: 'corporate_type',
    value: 'trip.corporate_type',
    sortable: false,
    width: 100,
}, ];


export const overseaHeaderConfig = [{
    name: 'start_time',
    value: 'trip.start_time',
    sortable: false,
    width: 120,
}, {
    name: 'end_time',
    value: 'trip.end_time',
    sortable: false,
    width: 120,
}, {
    name: 'user_name',
    value: 'name',
    sortable: false,
    width: 100,
}, {
    name: 'plate_no',
    value: 'plate_no',
    sortable: false,
    width: 100,
} ];
