import {
    ERROR_CODE_0,
    ERROR_CODE_2000,
    ERROR_CODE_2001,
    ERROR_CODE_2004,
    ERROR_CODE_2005,
    ERROR_CODE_2006,
    ERROR_CODE_2007,
    ERROR_CODE_2008,
    ERROR_CODE_2009,
    ERROR_CODE_2010,
    ERROR_CODE_3000,
    ERROR_CODE_3001
} from '../../../constants/error';

const lang = {
    'error': 'OOPS!',
    [`error_${ERROR_CODE_0}`]: 'Something went wrong. Please try again later.',
    [`error_${ERROR_CODE_2000}`]: 'Email address is required and should be in a valid format.',
    [`error_${ERROR_CODE_2001}`]:
        ' already exists in the system, or is repeated in your uploaded file.',
    [`error_${ERROR_CODE_2004}`]: 'Name, Email, and Group Name are required in the file.',
    [`error_${ERROR_CODE_2005}`]: 'Name, Email, and Group Name are required in the file.',
    [`error_${ERROR_CODE_2006}`]: 'Name, Email, and Group Name are required in the file.',
    [`error_${ERROR_CODE_2007}`]: ' already exists in another GoShare for Business user list.',
    [`error_${ERROR_CODE_2008}`]: 'Name, Email, and Group Name are required in the file.',
    [`error_${ERROR_CODE_2009}`]: 'Wrong column names. Please refer to the template.',
    [`error_${ERROR_CODE_2010}`]: 'Emails can only be business email address on your own work domain.',
    [`error_${ERROR_CODE_3000}`]: 'Group name already exists.',
    [`error_${ERROR_CODE_3001}`]: 'Group can be deleted only if there is no user in it.',
};

export default lang;