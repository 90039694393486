import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLocaleMap, defaultLanguage } from '../locale';
import { setLocale } from 'react-redux-i18n';

export default function useRegion(permit) {
    const dispatch = useDispatch();
    const changeLocale = useCallback(
        (locale) => {
            localStorage.setItem('locale', locale);
            dispatch(setLocale(locale));
        },
        [dispatch],
    );
    const localeMap = getLocaleMap();
    const accountReducer = useSelector(state => state.account);
    const { langList } = accountReducer;
    const supportLocaleMap = langList.map(item => localeMap[item]);
    const defaultSupportLocale = localeMap[langList[0]] ? langList[0] : defaultLanguage;

    useEffect(() => {
        if (langList.length) {
            let clientLocale = localStorage.getItem('locale');
            if (langList.indexOf(clientLocale) < 0) {
                localStorage.removeItem('locale');
                clientLocale = defaultSupportLocale;
            }
            if (!clientLocale) {
                clientLocale = defaultSupportLocale;
            }
            changeLocale(clientLocale);
        }
    }, [langList, changeLocale, defaultSupportLocale]);

    return {
        defaultSupportLocale,
        langList: supportLocaleMap,
        langListLoaded: !!(langList.length > 0),
        changeLocale,
    };
};
