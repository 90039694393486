import React from 'react';
import PropTypes from 'prop-types';
import Cell from '../../components/ListView/Cell';
import PaymentStatus from '../../components/PaymentStatus/PaymentStatus';
import Duration from '../../components/Duration/Duration';
import CorporateType from '../../components/CorporateType/CorporateType';
import Datetime from '../../components/Datetime';
import { thousandsSeparator } from '../../helpers/utils';


const ListRow = ({ columns, data, onClick }) => {

    const renderField = (renderData, key, width) => {
        let el;
        const value = typeof renderData[key] === 'string' && renderData[key].trim() === '' ?
            ' ' : renderData[key];

        switch (key) {
        case 'start_time':
        case 'end_time':
            el = <Datetime time={ value } />;
            break;
        case 'duration':
            el = (<Duration value={ value } />);
            break;
        case 'corporate_type':
            el = (<CorporateType type={ value } />);
            break;
        case 'subtotal':
        case 'allowance':
        case 'price':
        case 'refund_price':
            el = thousandsSeparator(value);
            break;
        case 'payment_status':
            el = (<PaymentStatus status={ value } />);
            break;
        case 'user_name':
            el = ( <span className="name">{ value }</span> );
            break;
        default:
            el = ( <span>{ value }</span> );
            break;
        }
        return (
            <Cell
                key={ `trip-${ renderData.rental_id }-${ key }` }
                name={ key }
                style={ { width } }
            >
                { el }
            </Cell>
        );
    };
    return columns.map(({ key, width }, i) => {
        return renderField(data, key, width);
    });
};

ListRow.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        user_name: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
    }).isRequired,
    onClick: PropTypes.func,
};

ListRow.defaultProps = {
    onClick: () => {},
};

export default ListRow;