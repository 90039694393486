import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Cell = ({ children, className, name, ...props }) => {
    const cls = classNames({
        'cell': true,
        [`cell-${ name }`]: !!name,
        [className]: !!className,
    });

    return (
        <div className={ cls } { ...props }>
            <div className="cell-content">
                { children }
            </div>
        </div>
    );
};
Cell.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
};
Cell.defaultProps = {
    className: '',
    name: '',
};

export default Cell;