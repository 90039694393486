import {
    PAYMENT_STATUS_SUCCESS,
    PAYMENT_STATUS_FAIL,
    PAYMENT_STATUS_UNPAID,
    PAYMENT_STATUS_PENDING,

    CORPORATE_TYPE_BASIC,
    CORPORATE_TYPE_VIP,

} from '../../../constants/trip';

import {
    TRIP_ACTIVITY,
} from '../../../constants/routes';

import Routes from './routes';

const paymentStatus = {
    [PAYMENT_STATUS_SUCCESS]: '付款成功',
    [PAYMENT_STATUS_FAIL]: '付款失敗',
    [PAYMENT_STATUS_UNPAID]: '尚未付款',
    [PAYMENT_STATUS_PENDING]: '付款處理中',
};
const corporateType = {
    [CORPORATE_TYPE_BASIC]: '企業用戶',
    [CORPORATE_TYPE_VIP]: '企業專屬',
};
const lang = {
    'document_title': Routes[TRIP_ACTIVITY],
    'start_time': '開始時間',
    'end_time': '結束時間',
    'subtotal': '小計',
    'allowance': '企業優惠',
    'corporate_type': '方案',
    'corporate_type_map': corporateType,
    'payment_status_map': paymentStatus,
    'refund': '退費金額',
    'distance': '總騎乘里程'
};
export default lang;
