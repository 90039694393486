import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import ActionHeader from '../../components/ActionHeader';
import FlexGroup from '../../components/FlexGroup';
import ChoiceGroup from '../../components/ChoiceGroup';
import SearchField from '../../components/SearchField';
import DatetimeRangeFilter from '../../components/DatetimeRangeFilter';
import MultiselectFilter from '../../components/MultiselectFilter';
import { getCorporareTypeOptions } from '../../components/CorporateType/CorporateType';
import { getPaymentStatusOptions } from '../../components/PaymentStatus/PaymentStatus';
import { convertToUTC, getPassNDays } from '../../helpers/time-handler';
import { updateDocumentTitle, fetchTripActivityList, clearTripActivity, logout } from '../../actions';
import { getDefaultPageSize } from '../../components/Pagination/PageSizeSelect';
import RegionFeature from '../../components/RegionFeature';
import List from './List';
import account from '../../helpers/account';
import history from '../../common/history';
import {
    USER_MANAGEMENT,
} from '../../constants/routes';
import permissionHandler from '../../helpers/permission-handler';
import {
    AUTH_VIEW_TRIP_ACTIVITY, ADMIN, OVERSEA_ADMIN,
} from '../../constants/permission';

import './trip-activity.scss';

const FILTER_NAME = 'name';
const FILTER_PLATE = 'plate_no';
const FILTER_RESERVE_TIME = 'reserve_time';
const FILTER_RESERVE_START_TIME = 'reserve_start_time';
const FILTER_RESERVE_END_TIME = 'reserve_end_time';
const FILTER_CORPORATE_TYPE = 'corporate_type';
const FILTER_PAYMENT_STATUS = 'payment_status';

const LAST_SECOND_OF_DAY = '23:59:59';

const TripActivity = (i18n) => {
    const dispatch = useDispatch();
    const { locale } = useSelector(state => state.i18n);
    const { tripActivityList } = useSelector(state => state.trip);
    const { permission, role } = useSelector(state => state.account);
    const dataValue = useRef();
    const searchType = useRef(FILTER_NAME);
    const [searchKey, setSearchKey] = useState();
    const [searchCategory, setSearchCategory] = useState();
    const [rsvStartTime, setRsvStartTime] = useState();
    const [rsvEndTime, setRsvEndTime] = useState();
    const [initSearchStart, setInitSearchStart] = useState(convertToUTC(getPassNDays(31)));
    const [initSearchEnd, setInitSearchEnd] = useState(convertToUTC(getPassNDays(-1)));
    const [corporateType, setCorporateType] = useState();
    const [paymentStatus, setPaymentStatus] = useState();
    const [appliedTripDateRange, setAppliedTripDateRange] = useState(false);
    const [fetchTimer, setFetchTimer] = useState();
    const [searchPlaceholder, setSearchPlaceholder] = useState(I18n.t('user.name'));
    const [pageSize, setPageSize] = useState(getDefaultPageSize().value);
    const [pageIndex, setPageIndex] = useState(1);

    const fetchTripActivity = useCallback(() => {
        let params = {
            page: pageIndex,
            size: pageSize,
            [FILTER_CORPORATE_TYPE]: corporateType,
            [FILTER_PAYMENT_STATUS]: paymentStatus,
            [FILTER_RESERVE_START_TIME]: rsvStartTime || initSearchStart,
            [FILTER_RESERVE_END_TIME]: rsvEndTime || initSearchEnd,
        };
        if (dataValue.current !== '') {
            params = { ...params, [searchCategory]: searchKey };
        }
        setFetchTimer(moment().format(I18n.t('datetime_format.long')));
        dispatch(fetchTripActivityList(params));
    }, [
        pageIndex,
        pageSize,
        rsvStartTime,
        rsvEndTime,
        corporateType,
        paymentStatus,
        initSearchStart,
        initSearchEnd,
        dispatch,
        searchKey,
        searchCategory,
    ]);

    useEffect(() => {
        if (permission.length !== 0) {
            if (!permissionHandler({ requiredList: [AUTH_VIEW_TRIP_ACTIVITY] })) {
                if (!account.exists()) {
                    dispatch(logout());
                }
                else {
                    history.push(USER_MANAGEMENT);
                }
            }
        }
    }, [dispatch, permission]);

    useEffect(() => {
        return () => {
            dispatch(clearTripActivity());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(updateDocumentTitle(I18n.t('trip.document_title')));
        setSearchPlaceholder(I18n.t(`${searchType.current}`));
    }, [ dispatch, locale ]);

    useEffect(() => {
        if (role === ADMIN || role === OVERSEA_ADMIN) {
            fetchTripActivity();
        }
    }, [
        dispatch,
        fetchTripActivity,
        role
    ]);

    const handleFilterChange = (filterType) => (value) => {
        setPageIndex(1);
        switch (filterType) {
        case FILTER_CORPORATE_TYPE:
            setCorporateType(value.inputSelect);
            break;
        case FILTER_PAYMENT_STATUS:
            setPaymentStatus(value.inputSelect);
            break;
        case FILTER_RESERVE_TIME:
        default:
            const { from, to } = value;
            const newAppliedState = !!(from & to);
            setAppliedTripDateRange( newAppliedState );
            setRsvStartTime(from ? convertToUTC(from) : undefined);
            setRsvEndTime(to ? convertToUTC(to) : undefined);
            setInitSearchStart(from ? undefined : convertToUTC(getPassNDays(31)));
            setInitSearchEnd(to ? undefined : convertToUTC(getPassNDays(-1)));
            break;
        }
    };
    const handleSubmit = () => {
        setPageIndex(1);
        handleFilterChange(FILTER_CORPORATE_TYPE)({ inputSelect: [] });
        handleFilterChange(FILTER_PAYMENT_STATUS)({ inputSelect: [] });
        handleFilterChange(FILTER_RESERVE_TIME)({});
        setSearchKey(dataValue.current);
        setSearchCategory(searchType.current);
    };

    const handleChange = ({
        page = pageIndex,
        size = pageSize,
    }) => {
        setPageIndex(page);
        setPageSize(size);
    };

    const handleSearchTypeChange = (e) => {
        searchType.current = e.currentTarget.value;
        setSearchPlaceholder(I18n.t(`${searchType.current}`));
    };
    const handleSearchValueChange = (term) => {
        dataValue.current = term;
    };

    const initSearchEndToday = moment(initSearchEnd).add(-1, 'day').toISOString();

    return (
        <div className="trip-activity-list app-feature-wrapper">
            <ActionHeader title={ I18n.t('trip.document_title') } />
            <FlexGroup start className="search-section">
                <div className="choice-group-field">
                    <ChoiceGroup
                        defaultChecked={ searchType.current }
                        options={ [
                            {
                                id: FILTER_NAME,
                                name: I18n.t(FILTER_NAME),
                                value: FILTER_NAME,
                            }, {
                                id: FILTER_PLATE,
                                name: I18n.t('plate_no'),
                                value: FILTER_PLATE,
                            }
                        ] }
                        onChange={ handleSearchTypeChange }
                    />
                    <SearchField
                        placeholder={ searchPlaceholder }
                        onChange={ handleSearchValueChange }
                        onSubmit={ handleSubmit }
                        value={ dataValue.current }
                    />
                </div>
                <section className="filters">
                    <DatetimeRangeFilter
                        title={ I18n.t('record_periods') }
                        inputFrom={ rsvStartTime }
                        inputTo={ rsvEndTime }
                        onApply={ handleFilterChange(FILTER_RESERVE_TIME) }
                        applied={ appliedTripDateRange }
                        endTime={ LAST_SECOND_OF_DAY }
                        range={ [31, 'days'] }
                    />
                    <RegionFeature hideBy>
                        <MultiselectFilter
                            title={ I18n.t('trip.corporate_type') }
                            defaultSelected={ corporateType }
                            options={ getCorporareTypeOptions(true) }
                            onChange={ handleFilterChange(FILTER_CORPORATE_TYPE) }
                        />
                        <MultiselectFilter
                            title={ I18n.t('payment_status') }
                            defaultSelected={ paymentStatus }
                            options={ getPaymentStatusOptions(true) }
                            onChange={ handleFilterChange(FILTER_PAYMENT_STATUS) }
                        />
                    </RegionFeature>
                </section>
            </FlexGroup>
            <hr />
            <List
                list={ tripActivityList }
                pageSize={ pageSize }
                onChange={ handleChange }
                onFetch={ fetchTripActivity }
                timer={ fetchTimer }
                initSearchFrom={ initSearchStart }
                initSearchTo={ initSearchEndToday }
            />
        </div>
    );
};

export default TripActivity;
