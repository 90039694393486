import {
    FETCH_ALLOWANCE,
} from '../constants/action-type';
import { asyncInterface } from './util';
import api from '../api';

export const fetchAllowance = () => (dispatch) => {
    const fetchFunction = () => api.fetchAllowance()();
    return asyncInterface({
        asyncAction: fetchFunction,
        type: FETCH_ALLOWANCE,
        dispatch,
    });
};