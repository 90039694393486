export const IMPORT_LIMIT_3MB = 3 * 1024 * 1024;

export const IMPORT_TYPE = [{
    name: 'user.create-import',
    description: 'user.create-description',
    value: 0,
}, {
    name: 'user.update-import',
    description: 'user.update-description',
    value: 1,
}, {
    name: 'user.remove-import',
    description: 'user.remove-description',
    value: 2,
}];