import {
    USER_VERIFIED,
    USER_UNVERIFIED,
} from '../../../constants/users';

import {
    USER_MANAGEMENT,
} from '../../../constants/routes';

import Routes from './routes';

const verStatus = {
    [USER_VERIFIED]: '已驗證',
    [USER_UNVERIFIED]: '未驗證',
};

const lang = {
    'document_title': Routes[USER_MANAGEMENT],
    'name': '姓名',
    'email': '電子信箱',
    'new': '新增',
    'edit': '編輯會員',
    'group': '群組名稱',
    'remove': '刪除會員',
    'verification_status': '驗證狀態',
    'confirm-remove': '確定刪除以下會員：',
    'ver_status_map': verStatus,
    'create-import': '新增會員',
    'create-description': '新會員將收到邀請信',
    'update-import': '新增會員並更新現有會員資料',
    'update-description': '新會員將收到邀請信，現有會員資料將被更新',
    'remove-import': '刪除會員',
    'remove-description': '刪除的會員將無法享受 GoShare for Business 企業方案',
    'upload-title': '請於此上傳您的會員名單',
    'upload-tmpl-desc-1': '欄位需包含姓名、電子信箱、群組名稱。開始匯入 CSV 檔案前，%{download}',
    'upload-tmpl-desc-2': '請先下載範本。'
};
export default lang;