import React from 'react';
import PropTypes from 'prop-types';
import FlexGroup from '../../components/FlexGroup';
import { Translate } from 'react-redux-i18n';
import ButtonGroup from '../../components/ButtonGroup';
import Button from '../../components/Form/Button';
import Form from '../../components/Form';
import UploadFile from '../../components/Form/UploadFile';

import './action-header.scss';

const ActionHeader = ({
    title,
    innerRef,
    onExport,
    onImport,
}) => {
    return (
        <FlexGroup start spaceBetween className="action-header">
            <h2>{ title }</h2>
            { (onExport || onImport) ? (
                <Form className="section">
                    <ButtonGroup>
                        {
                            onExport ? (
                                <Button
                                    onClick={ onExport }
                                    color="action"
                                    type="button"
                                >
                                    <Translate value="export" />
                                </Button>
                            ) : null
                        }
                        {
                            onImport ? (
                                <UploadFile
                                    title="import"
                                    className="import-btn btn-action"
                                    accept=".csv, .txt"
                                    onImport={ onImport }
                                />
                            ) : null
                        }
                    </ButtonGroup>
                </Form>
            ) : null }
        </FlexGroup>
    );
};

ActionHeader.propTypes = {
    title: PropTypes.string,
    innerRef: PropTypes.shape(),
    onExport: PropTypes.func,
    onImport: PropTypes.func,
};

ActionHeader.defaultProps = {
    title: '',
    innerRef: undefined,
    onExport: undefined,
    onImport: undefined,
};

export default ActionHeader;