import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import { closeSystemDialog } from '../../actions';
import Modal from '../Modal';
import './error-message.scss';

const SystemDialog = () => {
    const dispatch = useDispatch();
    const { isOpen, title, message } = useSelector(state => state.system);

    const onClose = () => {
        dispatch(closeSystemDialog());
    };

    return (
        <React.Fragment>
            { isOpen && (
                <Modal
                    title={ title }
                    className="popup-error-message"
                    onClose={ onClose }
                >
                    <p className="error">
                        <span className="icon fas fa-exclamation-circle" />
                        <span className="error-paragraph">{ message }</span>
                    </p>
                    <Button outline onClick={ onClose }>
                        <Translate value="ok" />
                    </Button>
                </Modal>
            )
            }
        </React.Fragment>
    );
};

export default SystemDialog;