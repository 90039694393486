import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import PleaseLoginDialog from '../../components/PleaseLoginDialog';
import RefreshPermission from '../../components/RefreshPermission';
import './app-container.scss';
import { useSelector } from 'react-redux';

const App = (props) => {
    const { title } = useSelector(state => state.general);
    const { locale } = useSelector(state => state.i18n);
    const [ lang ] = locale.split('-');
    const [meta, setMeta] = useState({
        title: `${I18n.t('business_management')} | `,
    });
    const { children, history } = props;
    const appClassName = classNames({
        'app': true,
        [locale.toLowerCase()]: true,
        [lang]: true,
    });
    useEffect(() => {
        setMeta({
            title: `${I18n.t('business_management')} | ${title}`,
        });
    }, [title, locale]);

    return (
        <div className={ appClassName }>
            <Helmet>
                <title>{ meta.title }</title>
            </Helmet>
            <SideBar history={ history } />
            <main className="main-content">
                <Header />
                <section className="app-container">
                    { children }
                </section>
            </main>
            <RefreshPermission history={ history } />
            <PleaseLoginDialog />
        </div>
    );
};

export default withRouter(App);