import React from 'react';
import { useSelector } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import FlexGroup from '../FlexGroup';
import Modal from '../Modal';
import './please-login-dialog.scss';

const PleaseLoginDialog = () => {
    const { showPleaseLoginDialog } = useSelector(state => state.general);

    return (
        <React.Fragment>
            { showPleaseLoginDialog && (
                <Modal
                    className="please-login-dialog"
                    title={ I18n.t('please_login') }
                    disableCloseBtn
                    disabledCloseOnBg
                >
                    <Translate value="please_login_message" />
                    <FlexGroup end>
                        <Button className="btn-login" color="primary" onClick={ () => window.location.reload() }>
                            <Translate value="login" />
                        </Button>
                    </FlexGroup>
                </Modal>
            )
            }
        </React.Fragment>
    );
};

export default PleaseLoginDialog;