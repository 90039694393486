import {
    GROUP_USER,
} from '../../../constants/routes-with-hierarchy';

import {
    TRIP_ACTIVITY,
    USER_MANAGEMENT,
    GROUP,
    BILLING,
} from '../../../constants/routes';

const lang = {
    [TRIP_ACTIVITY]: '騎乘紀錄',
    [USER_MANAGEMENT]: '會員名單',
    [GROUP]: '群組名單',
    [BILLING]: '方案',
    [`category_${ GROUP_USER }`]: '會員管理',
};

export default lang;