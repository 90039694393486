const datetimeFormat = {
    'date': 'YYYY-MM-DD',
    'time': 'HH:mm:ss',
    'long': 'YYYY-MM-DD HH:mm:ss',
    'utc': 'YYYY-MM-DDTHH:mm:00',
};
const lang = {
    'lang': 'English',
    'business_management': 'GoShare for Business',
    'login': 'Log In',
    'logout': 'Log Out',
    'export': 'Export',
    'exporting': 'Exporting',
    'export_finish': 'We\'re preparing your data and will send you a download link via email.',
    'import': 'Import',
    'import_success': 'Imported Successfully',
    'cancel': 'Cancel',
    'from': 'From',
    'to': 'To',
    'days': 'Days',
    'duration': 'Duration',
    'duration-hr': '%{hr} hrs',
    'duration-min': '%{min} mins',
    'duration-sec': '%{sec} sec',
    'email': 'Email',
    'name': 'Name',
    'ok': 'OK',
    'charged': 'Charged',
    'payment_status': 'Payment Status',
    'save': 'Save',
    'apply': 'Apply',
    'choose_files': 'Choose file',
    'no_file': 'No file chosen',
    'datetime_format': datetimeFormat,
    'total_count': 'Counts',
    'last_udpated': 'Last Updated',
    'delete': 'Delete',
    'file_size_3MB_warning': 'File size should be smaller than 3 MB.',
    'invalidAccountLoginError': 'Wrong account or password. Please try again.',
    'empty_row': 'No data found.',
    'record_periods': 'Period',
    'plate_no': 'Plate No.',
    'please_login': 'Please log in again',
    'please_login_message': (
        'To keep your work secure, your session has timed out. ' +
        'To log back in, please refresh your browser, or hit Log In below.'
    ),
};

export default lang;
