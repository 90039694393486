import { FETCH_ALLOWANCE } from '../constants/action-type';

const INITIAL_STATE = {
    general: null,
    vip: null,
};


const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case FETCH_ALLOWANCE:
        const { general, vip } = action.data;
        return {
            ...state,
            general,
            vip,
        };
    default:
        return state;
    }
};

export default reducer;
