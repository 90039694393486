// general
export const ERROR = 'ERROR';
export const UPDATE_DOCUMENT_TITLE = 'UPDATE_DOCUMENT_TITLE';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_MENU_CATEGORY = 'TOGGLE_MENU_CATEGORY';
export const CLEAR_MENU_CATEGORY_ACTIVE = 'CLEAR_MENU_CATEGORY_ACTIVE';
export const SHOW_PLEASE_LOGIN_DIALOG = 'SHOW_PLEASE_LOGIN_DIALOG';

// system
export const OPEN_SYSTEM_DIALOG = 'OPEN_SYSTEM_DIALOG';
export const CLOSE_SYSTEM_DIALOG = 'CLOSE_SYSTEM_DIALOG';

// async
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_FINISHED = 'ASYNC_FINISHED';

// account
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const GET_COMPANY_AUTH = 'GET_COMPANY_AUTH';
export const CLEAR_PERMISSION = 'CLEAR_PERMISSION';

// user
export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const PATCH_USER = 'PATCH_USER';
export const CREATE_USER = 'CREATE_USER';
export const CLEAR_USER_LIST = 'CLEAR_USER_LIST';
export const FETCH_DOMAIN = 'FETCH_DOMAIN';

// group
export const FETCH_GROUP = 'FETCH_GROUP';
export const PATCH_GROUP = 'PATCH_GROUP';
export const CREATE_GROUP = 'CREATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const CLEAR_GROUP = 'CLEAR_GROUP';

// billing
export const FETCH_ALLOWANCE = 'FETCH_ALLOWANCE';

// trip-activity
export const FET_TRIP_ACTIVITY = 'FET_TRIP_ACTIVITY';
export const CLEAR_TRIP_ACTIVITY = 'CLEAR_TRIP_ACTIVITY';

// File
export const EXPORT_FILE = 'EXPORT_FILE';
export const IMPORT_FILE = 'IMPORT_FILE';
export const CLOSE_EXPORT = 'CLOSE_EXPORT';
