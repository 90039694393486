import {
    TOGGLE_MENU,
    TOGGLE_MENU_CATEGORY,
    CLEAR_MENU_CATEGORY_ACTIVE,
    UPDATE_DOCUMENT_TITLE,
    SHOW_PLEASE_LOGIN_DIALOG,
} from '../constants/action-type';

const INITIAL_STATE = {
    title: '',
    menuOpen: false,
    menuCategoryOpen: {},
    menuCategoryActive: '',
    showPleaseLoginDialog: false,
};


const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case TOGGLE_MENU:
        return {
            ...state,
            menuOpen: !state.menuOpen,
        };
    case CLEAR_MENU_CATEGORY_ACTIVE:
        return {
            ...state,
            menuCategoryActive: '',
        };
    case TOGGLE_MENU_CATEGORY:
        const { category, switchStatus, activeCategory } = action.data;
        state.menuCategoryOpen[category] = switchStatus;
        state.menuCategoryActive = activeCategory;
        return {
            ...state,
        };
    case UPDATE_DOCUMENT_TITLE:
        return {
            ...state,
            title: action.data,
        };
    case SHOW_PLEASE_LOGIN_DIALOG:
        return {
            ...state,
            showPleaseLoginDialog: true,
        };
    default:
        return state;
    }
};

export default reducer;
