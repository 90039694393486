import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import {
    USER_VERIFIED,
    USER_UNVERIFIED,
} from '../../constants/users';

export const getUserVerStatusOptions = () => [
    USER_VERIFIED,
    USER_UNVERIFIED,
].map(
    value => ({
        text: I18n.t(`user.ver_status_map.${ value }`),
        value,
    })
);

const UserVerStatus = ({ status, className }) => {
    let el;
    switch (status) {
    case USER_VERIFIED:
    case USER_UNVERIFIED:
        el = <Translate value={ `user.ver_status_map.${ status }` } />;
        break;
    default:
        el = <span>--</span>;
    }
    return el;
};

UserVerStatus.propTypes = {
    status: PropTypes.bool,
    className: PropTypes.string,
};

UserVerStatus.defaultProps = {
    status: undefined,
    className: '',
};

export default UserVerStatus;