import { LOGIN, LOGOUT, GET_COMPANY_AUTH } from '../constants/action-type';
import account from '../helpers/account';
import { TAIWAN } from '../constants/permission';
import { TAIWAN_SUPPORT_LANGUAGE_LIST } from '../locale';

const INITIAL_STATE = {
    id_token: '',
    isLoggedIn: false,
    profile: null,
    role: '',
    companyName: '',
    permission: [],
    region: null,
    langList: [],
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case LOGIN:
        const { profile, id_token, expires_in } = action.payload;
        const res = { ...profile, expires_in };
        account.set(res);
        account.setID(id_token);
        account.setProfile(res);
        account.setExpiresAt(res);
        return {
            ...state,
            isLoggedIn: true,
            profile,
            id_token
        };
    case LOGOUT:
        account.remove();
        account.setID('');
        account.setProfile(null);
        account.setExpiresAt(null);
        return {
            ...state,
            isLoggedIn: false,
            profile: null,
        };
    case GET_COMPANY_AUTH:
        const { authorities, role, company_name, business_type, support_lang_list } = action.data;
        return {
            ...state,
            companyName: company_name,
            role,
            permission: authorities,
            region: business_type || TAIWAN,
            langList: support_lang_list || TAIWAN_SUPPORT_LANGUAGE_LIST,
        };
    default:
        return state;
    }
};

export default reducer;