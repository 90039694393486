import {
    USER_VERIFIED,
    USER_UNVERIFIED,
} from '../../../constants/users';

import {
    USER_MANAGEMENT,
} from '../../../constants/routes';

import Routes from './routes';

const verStatus = {
    [USER_VERIFIED]: 'Verified',
    [USER_UNVERIFIED]: 'Not Verified',
};

const lang = {
    'document_title': Routes[USER_MANAGEMENT],
    'name': 'Name',
    'email': 'Email',
    'new': 'Add',
    'edit': 'Edit',
    'group': 'Group Name',
    'remove': 'Delete User(s)',
    'verification_status': 'Verification Status',
    'confirm-remove': 'Are you sure you want to delete the following user(s):',
    'ver_status_map': verStatus,
    'create-import': 'Add new users',
    'create-description': 'New users will receive invitation emails.',
    'update-import': 'Add new users and update profiles of existing users.',
    'update-description':
        'New users will receive invitation emails and profiles of existing users will be updated.',
    'remove-import': 'Delete users',
    'remove-description': 'Deleted users will not be eligible for Goshare for Business.',
    'upload-title': 'Import your user list here',
    'upload-tmpl-desc-1': `Columns should include Name, Email, and Group Name. %{download}
        the CSV template before importing your file.`,
    'upload-tmpl-desc-2': 'Download'
};

export default lang;