import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import account from '../../helpers/account';
import { togglePleaseLoginDialog } from '../../actions';
import { ROOT } from '../../constants/routes';

const RefreshPermission = (props) => {
    const dispatch = useDispatch();

    const handleVisibilityChange = useCallback(() => {
        const { history } = props;

        if (document.visibilityState === 'visible'
            && account.exists()
            && history.location.pathname !== ROOT) {
            if (account.diff()) {
                return dispatch(togglePleaseLoginDialog());
            }
        }
    }, [dispatch, props]);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange, false);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange, false);
        };
    }, [dispatch, handleVisibilityChange]);

    return (
        <div />
    );
};

export default RefreshPermission;