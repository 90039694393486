import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './button-group.scss';

const Component = ({ children, start, className }) => {
    const cls = classNames({
        'button-group': true,
        'start': start,
    }, className);

    return (
        <section className={ cls }>
            { children }
        </section>
    );
};
Component.propTypes = {
    start: PropTypes.bool,
    className: PropTypes.string,
};
Component.defaultProps = {
    start: false,
    className: '',
};

export default Component;
