import { createBusinessApiInstanceWithToken } from '../util';
const user = {
    fetchUserList: (params = {}) => {
        return createBusinessApiInstanceWithToken().get('/users?state=1', { params });
    },

    patchUser: (editArray = []) => {
        return createBusinessApiInstanceWithToken().patch('/users', { corporate_users: editArray });
    },

    createUser: (params = {}) => {
        return createBusinessApiInstanceWithToken().post('/users', params);
    },

    fetchDomain: () => {
        return createBusinessApiInstanceWithToken().get('/domains');
    },
};

export default user;