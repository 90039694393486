import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import {
    TYPE_SELECT,
} from '../Input/input-type';

class CustomSelect extends Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.arrayOf(PropTypes.shape({
            // translation key
            name: PropTypes.string,
            value: PropTypes.node,
        })),
        selected: PropTypes.node,
        onChange: PropTypes.func,
        required: PropTypes.bool,
        caption: PropTypes.string,
        disabled: PropTypes.bool,
        withCaption: PropTypes.bool,
        innerRef: PropTypes.shape(),
    };

    static defaultProps = {
        name: undefined,
        value: undefined,
        caption: '',
        selected: undefined,
        onChange: () => {},
        innerRef: undefined,
        required: false,
        disabled: false,
        withCaption: true,
    };

    constructor(props) {
        super(props);

        const { innerRef, value, selected } = props;

        this.elInput = innerRef || React.createRef();

        this.state = {
            value,
            selected,
        };
    }

    componentDidUpdate(prevProps) {
        const oldValue = prevProps.value;
        const { value } = this.props;
        if (oldValue !== value) {
            this.setState({ value });
        }
    }

    handleChange = e => {
        e.preventDefault();
        const { onChange } = this.props;

        this.setState({
            selected: e.currentTarget.value,
        }, () => {
            onChange(e);
        });
    };

    handleInvalid = e => {
        e.preventDefault();
        this.checkValidity(e.currentTarget);
    };

    checkValidity = el => {
        const checkTypes = [
            'valueMissing'
        ];
        let errorMessage = '';
        const { validity } = el;
        const { valid } = validity;
        if (!valid) {
            errorMessage = I18n.t('validation_error.generalInvalid');

            checkTypes.forEach(type => {
                if (validity[type]) {
                    errorMessage = I18n.t(`validation_error.${ type }`);
                }
            });
            this.setState({ invalidMessage: errorMessage });
        }
        else {
            this.setState({ invalidMessage: '' });
        }
    };

    renderErrorToolTip = () => {
        const { invalidMessage } = this.state;
        return <p className="error-message">{ invalidMessage }</p>;
    };

    renderOptions() {
        const { value } = this.state;
        return value.map(item => {
            return (
                <option key={ item.id } value={ item.id }>
                    { item.name }
                </option>
            );
        });
    }

    render() {
        const { name, required, disabled, withCaption, caption, ...rest } = this.props;
        const { selected } = this.state;
        const captionClass = classNames({
            caption: true,
            required: required,
        });
        const wrapperClass = classNames({
            'control-group': true,
            'disabled': disabled,
        });
        const controlClass = classNames({
            'control-wrapper': true,
        });

        return (
            <div className={ wrapperClass }>
                {
                    withCaption ?
                        (
                            <Translate className={ captionClass } value={ caption } tag="h5" />
                        ) :
                        null
                }
                <div className={ controlClass }>
                    <Input
                        { ...rest }
                        type={ TYPE_SELECT }
                        name={ name }
                        value={ selected }
                        onChange={ this.handleChange }
                        onInvalid={ (e) => {
                            this.handleInvalid(e);
                        } }
                        required={ required }
                    >
                        { this.renderOptions() }
                    </Input>
                    { this.renderErrorToolTip() }
                </div>
            </div>
        );
    }
}

export default CustomSelect;
