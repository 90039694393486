import routeWithHierarchy from '../../constants/routes-with-hierarchy';

const getHierarchicalMenu = (authorities, list) => {
    const availableMenuItems = (list || routeWithHierarchy).filter(menuItem => {
        const { auth, isMenuItem } = menuItem;
        return authorities.map(({ authority }) => authority ).indexOf(auth) > -1 && isMenuItem;
    }).sort((a, b) => (a.order - b.order));
    return availableMenuItems.reduce((menuMap, menuItem) => {
        const { route, category, showAsCategory } = menuItem;
        const list = menuMap[category] || [];
        menuMap[category] = list.concat({
            route,
            category,
            showAsCategory,
        });
        return menuMap;
    }, {});
};
export default getHierarchicalMenu;

export const getCategoryByPathName = (pathName) => {
    const target = routeWithHierarchy.find( item => {
        return (item.route === pathName && !item.showAsCategory);
    });

    return target?.category;
};