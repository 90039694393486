import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { thousandsSeparator } from '../../helpers/utils';


const TotalCount = ({ page, offset, count }) => {
    let totalCount = null;
    if (page && offset) {
        const end = page * offset;
        const start = end - offset;
        totalCount = (
            <p className="total-count">
                <Translate className="label" value="total_count" />
                { `${start}~${end} / ${thousandsSeparator(count)}` }
            </p>
        );
    }

    if (count !== undefined) {
        totalCount = (
            <p className="total-count">
                <Translate className="label" value="total_count" />
                <span>{ thousandsSeparator(count) }</span>
            </p>
        );
    }
    return totalCount;
};
TotalCount.propTypes = {
    count: PropTypes.number,
    page: PropTypes.number,
    offset: PropTypes.number,
};
TotalCount.defaultProps = {
    count: undefined,
    page: 0,
    offset: 0,
};

export default TotalCount;