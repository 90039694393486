import { Map, List, } from 'immutable';
import { FET_TRIP_ACTIVITY, CLEAR_TRIP_ACTIVITY } from '../constants/action-type';

const INITIAL_STATE = {
    tripActivityList: Map({
        total: undefined,
        data_list: List([]),
    }),
};


const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case FET_TRIP_ACTIVITY:
        const { page_index, page_count, total, data_list } = action.data;
        return ({
            ...state,
            tripActivityList: Map({
                page_index,
                page_count,
                total,
                data_list: List(data_list),
            }),
        });
    case CLEAR_TRIP_ACTIVITY:
        return ({
            tripActivityList: Map({
                data_list: List([]),
            }),
        });
    default:
        return state;
    }

};

export default reducer;