import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import { IMPORT_LIMIT_3MB } from '../../constants/file';
import ActionHeader from '../../components/ActionHeader';
import FlexGroup from '../../components/FlexGroup';
import ChoiceGroup from '../../components/ChoiceGroup';
import SearchField from '../../components/SearchField';
import MultiselectFilter from '../../components/MultiselectFilter';
import { getStatusTypeOptions } from '../../components/StatusType';
import Modal from '../../components/Modal';
import { getDefaultPageSize } from '../../components/Pagination/PageSizeSelect';
import moment from 'moment';
import List from './List';
import {
    updateDocumentTitle,
    fetchUserList,
    fetchGroup,
    fetchDomain,
    fetchUserFile,
    importUserFile,
    openSystemDialog,
    closeFileExport,
    clearUserList,
} from '../../actions';
import './user.scss';

const FILTER_NAME = 'name';
const FILTER_EMAIL = 'email';
const FILTER_STATUS_TYPE = 'verification_status';
const FILTER_GROUP = 'group_name';

const UserManagement = () => {
    const dispatch = useDispatch();
    const dataValue = useRef();
    const searchType = useRef(FILTER_NAME);
    const [searchKey, setSearchKey] = useState();
    const [selectedIdList, setSelectedIdList] = useState([]);
    const [pageSize, setPageSize] = useState(getDefaultPageSize().value);
    const [pageIndex, setPageIndex] = useState(1);
    const [dataSort, setDataSort] = useState();
    const [statusType, setStatusType] = useState([]);
    const [group, setGroup] = useState([]);
    const [importNotify, setImportNotify] = useState(false);
    const [searchPlaceholder, setSearchPlaceholder] = useState(I18n.t('user.name'));
    const [fetchTimer, setFetchTimer] = useState();

    const { locale } = useSelector(state => state.i18n);
    const { userList } = useSelector(state => state.user);
    const { groupList } = useSelector(state => state.group);
    const { exportFinished } = useSelector(state => state.file);
    const { asyncError } = useSelector(state => state.asyncHandler);


    const getUserList = useCallback((
        page = pageIndex,
        size = pageSize,
        sort = dataSort
    ) => {
        let params = {
            page,
            size,
            sort,
            [FILTER_STATUS_TYPE]: statusType,
            [FILTER_GROUP]: group,
        };
        if (dataValue.current !== '') {
            params = { ...params, [searchType.current]: searchKey };
        }
        setSelectedIdList([]);
        setFetchTimer(moment().format(I18n.t('datetime_format.long')));
        dispatch(fetchUserList(params));
    }, [dataSort, dispatch, pageIndex, pageSize, searchKey, statusType, group]);

    useEffect(() => {
        dispatch(fetchGroup());
        dispatch(fetchDomain());
        return () => {
            dispatch(closeFileExport());
            dispatch(clearUserList());
        };
    }, [dispatch]);

    useEffect(() => {
        getUserList(pageIndex, pageSize, dataSort);
    }, [dataSort, pageSize, pageIndex, getUserList]);

    useEffect(() => {
        dispatch(updateDocumentTitle(I18n.t('user.document_title')));
        setSearchPlaceholder(I18n.t(`${searchType.current}`));
    }, [ locale, dispatch ]);

    useEffect(() => {
        if (asyncError !== undefined && importNotify) {
            toggleImportNotify(false);
        }
        else if (importNotify) {
            dispatch(fetchGroup());
            getUserList();
        }
    }, [asyncError, importNotify, getUserList, dispatch]);

    const handleSearchTypeChange = (e) => {
        searchType.current = e.currentTarget.value;
        setSearchPlaceholder(I18n.t(`${searchType.current}`));
    };

    const handleFilterChange = (filterType) => (value) => {
        setPageIndex(1);
        switch (filterType) {
        case FILTER_STATUS_TYPE:
            setStatusType(value.inputSelect);
            break;
        case FILTER_GROUP:
            setGroup(value.inputSelect);
            break;
        default:
            break;
        }
    };

    const handleSubmit = () => {
        setPageIndex(1);
        setStatusType([]);
        setGroup([]);
        setSearchKey(dataValue.current);
    };

    const handleSearchValueChange = (term) => {
        dataValue.current = term;
    };

    const handleSelect = list => {
        setSelectedIdList(list);
    };

    const handleUnchecked = () => {
        setSelectedIdList([]);
    };

    const handleChange = ({
        page = pageIndex,
        size = pageSize,
        sort = dataSort,
    }) => {
        setDataSort(sort);
        setPageIndex(page);
        setPageSize(size);
    };

    const handleUploadChange = async (file, type) => {
        if (file.size > IMPORT_LIMIT_3MB) {
            return dispatch(openSystemDialog(
                I18n.t('error.error'),
                I18n.t('file_size_3MB_warning')
            ));
        }
        await dispatch(importUserFile(file, type));
        toggleImportNotify(true);
    };

    const handleExport = () => {
        return dispatch(fetchUserFile());
    };

    const toggleImportNotify = (notify) => {
        setImportNotify(notify);
    };

    const getGroupOptions = () => {
        return groupList.map(
            ({ name }) => ({
                text: name,
                value: name,
            })
        );
    };

    return (
        <div className="user-list app-feature-wrapper">
            <ActionHeader
                title={ I18n.t('user.document_title') }
                onImport={ handleUploadChange }
                onExport={ handleExport }
            />
            <FlexGroup start className="search-section">
                <div className="choice-group-field">
                    <ChoiceGroup
                        defaultChecked={ searchType.current }
                        options={ [
                            {
                                id: FILTER_NAME,
                                name: I18n.t('user.name'),
                                value: FILTER_NAME,
                            }, {
                                id: FILTER_EMAIL,
                                name: I18n.t('email'),
                                value: FILTER_EMAIL,
                            }
                        ] }
                        onChange={ handleSearchTypeChange }
                    />
                    <SearchField
                        placeholder={ searchPlaceholder }
                        onSubmit={ handleSubmit }
                        value={ dataValue.current }
                        onChange={ handleSearchValueChange }
                    />
                    <section className="filters">
                        <MultiselectFilter
                            title={ I18n.t('user.verification_status') }
                            defaultSelected={ statusType }
                            options={ getStatusTypeOptions(true) }
                            onChange={ handleFilterChange(FILTER_STATUS_TYPE) }
                        />
                        <MultiselectFilter
                            title={ I18n.t('user.group') }
                            defaultSelected={ group }
                            options={ getGroupOptions() }
                            onChange={ handleFilterChange(FILTER_GROUP) }
                        />
                    </section>
                </div>
            </FlexGroup>
            <hr />
            <List
                list={ userList !== null ? userList.data_list : [] }
                onAction={ ({ page }) => {
                    getUserList(page);
                } }
                pageCount={ userList && userList.page_count }
                pageIndex={ userList && userList.page_index }
                pageSize={ pageSize }
                onSelect={ handleSelect }
                onChange={ handleChange }
                onUncheckedAll={ handleUnchecked }
                selectedIdList={ selectedIdList }
                timer={ fetchTimer }
            />
            { importNotify && (
                <Modal
                    title={ I18n.t('import') }
                    onClose={ () => {
                        toggleImportNotify(false);
                    } }
                >
                    <FlexGroup start>
                        <span>{ I18n.t('import_success') }</span>
                    </FlexGroup>
                    <FlexGroup end>
                        <Button
                            color="primary"
                            onClick={ () => {
                                toggleImportNotify(false);
                            } }
                        >
                            <Translate value="ok" />
                        </Button>
                    </FlexGroup>
                </Modal>
            ) }
            { exportFinished && (
                <Modal
                    title={ I18n.t('exporting') }
                    onClose={ () => {
                        dispatch(closeFileExport());
                    } }
                >
                    <FlexGroup start>
                        <span>{ `${I18n.t('export_finish')}` }</span>
                    </FlexGroup>
                    <FlexGroup end>
                        <Button
                            color="primary"
                            onClick={ () => {
                                dispatch(closeFileExport());
                            } }
                        >
                            <Translate value="ok" />
                        </Button>
                    </FlexGroup>
                </Modal>
            ) }
        </div>
    );
};

export default UserManagement;