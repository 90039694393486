import {
    BILLING,
} from '../../../constants/routes';

import Routes from './routes';

const lang = {
    'document_title': Routes[BILLING],
    'general': '企業用戶',
    'vip': '企業專屬',
    'contract_period': '合約期間',
    'subsidy_amount': '優惠額度',
    'subsidy_all': '全額補助',
    'subsidy_price': '每趟優惠 %{count} 元',
    'subsidy_percent': '每趟優惠 %{count} ％',
    'subsidy_percent_max': '每趟優惠 %{count} ％，最高 %{max} 元',
    'itinerary': '行程額度',
    'itinerary_all': '無次數上限',
    'itinerary_day': '每日最多 %{count} 趟',
    'itinerary_month': '每月最多 %{count} 趟',
};

export default lang;