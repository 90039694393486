import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-redux-i18n';
import iconWarning from '../../assets/icon-warning.svg';

import './empty-list-blocker.scss';

const EmptyListBlocker = ({ show }) => {
    const style = classNames({
        'empty-list-blocker': true,
        'show': show,
    });

    return (
        <section className={ style }>
            <p>
                <img src={ iconWarning } alt="" />
                <Translate value="empty_row" />
            </p>
        </section>
    );
};

EmptyListBlocker.propTypes = {
    show: PropTypes.bool,
};
EmptyListBlocker.defaultProps = {
    show: true,
};

export default EmptyListBlocker;