import {
    FET_TRIP_ACTIVITY,
    CLEAR_TRIP_ACTIVITY,
} from '../constants/action-type';
import { asyncInterface } from './util';
import api from '../api';

export const fetchTripActivityList = ( params = {} ) => (dispatch) => {
    const fetchFunction = () => api.fetchTripActivityList(params)();
    return asyncInterface({
        asyncAction: fetchFunction,
        type: FET_TRIP_ACTIVITY,
        dispatch,
    });
};

export const clearTripActivity = () => {
    return {
        type: CLEAR_TRIP_ACTIVITY,
    };
};