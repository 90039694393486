import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import useLocale from '../../hooks/useLocale';

import './locale.scss';

const Locale = () => {
    const i18n = useSelector(state => state.i18n);
    const { translations } = i18n;
    const [localeKey, setLocaleKey] = useState(i18n.locale);
    const { langList, changeLocale } = useLocale();
    const handleLocaleChange = locale => {
        setLocaleKey(locale);
        changeLocale(locale);
    };
    const renderLangButtons = () => {
        const length = langList.length;
        const langButtons = [];
        for (let i = 0; i < length; i++) {
            const locale = langList[i]?.locale;
            if (locale) {
                const buttonClassName = classNames({
                    'lang-button': true,
                    'active': localeKey === locale,
                });
                langButtons.push(
                    <Button
                        key={ locale }
                        className={ buttonClassName }
                        onClick={ () => handleLocaleChange(locale) }
                    >
                        { translations[locale].lang }
                    </Button>
                );
                if (i !== length - 1) {
                    langButtons.push(' ｜ ');
                }
            }
            else {
                langButtons.pop();
            }
        }

        return langButtons;
    };

    useEffect(() => {
        const { locale } = i18n;
        if (locale !== localeKey) {
            setLocaleKey(locale);
        }
    }, [i18n, localeKey]);


    return (
        <div className="lang-buttons">{ renderLangButtons() }</div>
    );
};

export default Locale;