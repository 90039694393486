import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import {
    USER_VERIFIED,
    USER_UNVERIFIED,
} from '../../constants/users';

export const getStatusTypeOptions = () => [
    USER_VERIFIED,
    USER_UNVERIFIED,
].map(
    value => ({
        text: I18n.t(`user.ver_status_map.${ value }`),
        value: String(value),
    })
);
const StatusType = ({ type, className }) => {
    let el;

    switch (type) {
    case USER_VERIFIED:
    case USER_UNVERIFIED:
        el = <Translate value={ `user.ver_status_map.${ type }` } />;
        break;
    default:
        el = <span>--</span>;
    }

    return el;
};

StatusType.propTypes = {
    type: PropTypes.number,
    className: PropTypes.string,
};

StatusType.defaultProps = {
    type: -1,
    className: '',
};

export default StatusType;
