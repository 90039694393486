import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

ReactDOM.render(
    <Provider store={ store }>
        <HelmetProvider>
            <App />
        </HelmetProvider>
    </Provider>, document.getElementById('root'));
