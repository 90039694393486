import {
    EXPORT_FILE, IMPORT_FILE, CLOSE_EXPORT,
} from '../constants/action-type';
import { asyncInterface } from './util';
import api from '../api';

export const fetchUserFile = () => (dispatch) => {
    return asyncInterface({
        asyncAction: api.fetchUserFile(),
        type: EXPORT_FILE,
        dispatch,
    });
};

export const importUserFile = (file = null, type = 0) => (dispatch) => {
    return asyncInterface({
        asyncAction: api.importUserFile(file, type),
        type: IMPORT_FILE,
        dispatch,
    });
};

export const closeFileExport = () => {
    return {
        type: CLOSE_EXPORT,
    };
};