/* eslint-disable max-len */
import React, { lazy, Suspense, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import RingSpinner from './components/Spinner/Ring';
import AppContainer from './view/AppContainer';
import account from './helpers/account';
import history from './common/history';
import {
    TRIP_ACTIVITY,
    USER_MANAGEMENT,
    GROUP,
    BILLING,
    ROOT,
} from './constants/routes';
import { useDispatch } from 'react-redux';
import { updateDocumentTitle } from './actions';
import { I18n } from 'react-redux-i18n';

const SystemDialog = lazy(() => import('./components/SystemDialog'));
const Login = lazy(() => import('./view/Login'));
const TripActivity = lazy(() => import('./view/TripActivity'));
const UserManagement = lazy(() => import('./view/UserManagement'));
const Group = lazy(() => import('./view/Group'));
const Billing = lazy(() => import('./view/Billing'));

const WaitingComponent = (Component, noShow) => props => (
    <Suspense
        fallback={
            (noShow)
                ? (
                    <RingSpinner />
                ) : null
        }
    >
        <Component { ...props } />
    </Suspense>
);


const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener('popstate', () => {
            if (!account.exists()) {
                history.push(ROOT);
                dispatch(updateDocumentTitle(I18n.t('login')));
            }
            else {
                if (history.location.pathname === TRIP_ACTIVITY) {
                    dispatch(updateDocumentTitle(''));
                    dispatch(updateDocumentTitle(I18n.t('trip.document_title')));
                }
            }
        }, false);
    }, [dispatch]);

    return (
        <Router history={ history }>
            <Switch>
                <AppContainer>
                    <Route path={ ROOT } exact component={ WaitingComponent(Login) } />
                    <Route path={ TRIP_ACTIVITY } component={ WaitingComponent(TripActivity) } />
                    <Route path={ USER_MANAGEMENT } component={ WaitingComponent(UserManagement) } />
                    <Route path={ GROUP } component={ WaitingComponent(Group) } />
                    <Route path={ BILLING } component={ WaitingComponent(Billing) } />
                    <Route path="/:catalog" component={ WaitingComponent(SystemDialog, true) } />
                </AppContainer>
            </Switch>
        </Router>
    );
};

export default App;