import {
    BILLING,
} from '../../../constants/routes';

import Routes from './routes';

const lang = {
    'document_title': Routes[BILLING],
    'general': 'Business Account',
    'vip': 'Business Premium',
    'contract_period': 'Contract Period',
    'subsidy_amount': 'Spending Allowance',
    'subsidy_all': 'Full amount covered by the company',
    'subsidy_price': '$ %{count} off per trip',
    'subsidy_percent': '%{count} % off per trip',
    'subsidy_percent_max': '%{count} % off per trip, up to $ %{max} covered',
    'itinerary': 'Trip Allowance',
    'itinerary_all': 'Unlimited trips',
    'itinerary_day': '%{count} trips per day',
    'itinerary_month': '%{count} trips per month',
};

export default lang;