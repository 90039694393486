export const thousandsSeparator = number => {
    return (number || 0).toString().replace(/(\d{1,3})(?=(\d{3})+$)/g, '$1,');
};

export const convertDistance = (distance = 0) => {
    const [km, m] = Math.abs(distance).toString().split('.');
    const beautifulKM = thousandsSeparator(km);
    const metersWithPoint = m ? `.${ m }` : '';
    return `${ beautifulKM }${ metersWithPoint } KM`;
};
// duration = seconds
export const convertDuration = (duration = 0) => {
    const hr = Math.floor(duration / 3600);
    const min = Math.floor(duration / 60 % 60);
    const sec = duration % 60;
    let durationObject = {};

    if (hr) {
        durationObject.hr = hr;
    }

    if (min) {
        durationObject.min = min;

    }

    if ( (!hr && !min) || sec) {
        durationObject.sec = sec;
    }

    return durationObject;
};