import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate, I18n } from 'react-redux-i18n';
import RingSpinner from '../../Spinner/Ring';
import Modal from '../../Modal';
import FlexGroup from '../../FlexGroup';
import Blocker from '../../Blocker';
import ButtonGroup from '../../ButtonGroup';
import Button from '../Button';
import RadioGroup from '../RadioGroup';
import { IMPORT_TYPE } from '../../../constants/file';
import InsertedTranslate from '../../InsertedTranslate';

const UploadFile = ({ accept, title, innerRef, onImport, className }) => {
    const [ loading, setLoading ] = useState(false);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ file, setFile ] = useState();
    const [ importType, setImportType ] = useState(0);
    const [ invalidMessage, setInvalidMessage ] = useState('');

    // eslint-disable-next-line no-unused-vars
    const { locale } = useSelector(state => state.i18n);

    const handleChange = (file, type) => {
        if (!file) {
            return setInvalidMessage(I18n.t('validation_error.valueMissing'));
        }
        else {
            const ret = onImport(file, type);
            if (ret instanceof Promise) {
                setLoading(true);
                ret.then(
                    () => {
                        handleClose();
                    }
                ).finally(() => {
                    setLoading(false);
                });
            }
        }
    };

    const handleImportChange = (value) => {
        setImportType(value);
    };

    const handleClose = () => {
        setFile();
        setIsOpen(false);
        setImportType(0);
        setInvalidMessage('');
    };

    const renderErrorToolTip = () => {
        return <p className="error-message">{ invalidMessage }</p>;
    };

    const buttonStyle = classNames(
        'button',
        'btn',
        className,
    );

    const spinnerStyle = classNames({
        'spinner-container': true,
        hide: !loading,
    });

    return (
        <React.Fragment>
            <Button
                onClick={ () => setIsOpen(true) }
                color="action"
                className={ buttonStyle }
                type="button"
            >
                <Translate value={ title } />
            </Button>
            { isOpen &&
                (
                    <Modal
                        title={ I18n.t(`${title}`) }
                        onClose={ handleClose }
                        className="import-action"
                    >
                        <div className="upload-info">
                            <Translate className="title" value="user.upload-title" tag="h5" />
                            <div className="description">
                                <InsertedTranslate
                                    value="user.upload-tmpl-desc-1"
                                    replacement="download"
                                >
                                    <a
                                        href={ process.env.PUBLIC_URL + '/user-import.csv' }
                                        download
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        { I18n.t('user.upload-tmpl-desc-2') }
                                    </a>
                                </InsertedTranslate>

                            </div>
                            <FlexGroup start align-start className="control-group">
                                <label htmlFor="fileUpload" className="file-upload">
                                    <input
                                        ref={ innerRef }
                                        name="fileUpload"
                                        id="fileUpload"
                                        type="file"
                                        accept={ accept }
                                        className="file-input"
                                        onChange={ (e) => setFile(e.target.files[0]) }
                                    />
                                    <span className={ buttonStyle }>
                                        <Translate value="choose_files" />
                                    </span>
                                    <span className="import-file">
                                        { file ? file.name : I18n.t('no_file') }
                                    </span>
                                </label>
                            </FlexGroup>
                            { renderErrorToolTip() }
                        </div>
                        <RadioGroup
                            value={ IMPORT_TYPE }
                            selected={ importType }
                            onChange={ handleImportChange }
                        />
                        <ButtonGroup className="modal-button-group">
                            <Button
                                type="button"
                                onClick={ handleClose }
                            >
                                <Translate value="cancel" />
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                onClick={ () => {
                                    handleChange(file, importType);
                                } }
                            >
                                <Translate value={ title } />
                            </Button>
                        </ButtonGroup>
                        <Blocker className={ spinnerStyle }>
                            <RingSpinner />
                        </Blocker>
                    </Modal>
                )
            }
        </React.Fragment>
    );
};

UploadFile.propTypes = {
    title: PropTypes.string,
    accept: PropTypes.string,
    innerRef: PropTypes.shape(),
    className: PropTypes.string,
    onImport: PropTypes.func,
};

UploadFile.defaultProps = {
    title: '',
    accept: '*',
    innerRef: undefined,
    className: '',
    onImport: undefined,
};


export default UploadFile;