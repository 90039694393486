const lang = {
    'title': 'Action',
    'active_status': '服務狀態',
    'online': '上線',
    'offline': '下線',
    'todo': '標籤',
    'none': 'NONE',
    'dispatch': 'DISPATCH',
    'swap': 'SWAP',
    'maintain': 'MAINTAIN',
    'swap_f': 'SWAP-FLOATING',
    'done': '完成',
    'done&online': '完成 & 上線',
    'no_scooter': '沒有選擇車輛',
};
export default lang;
