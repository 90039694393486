import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BusinessTitle } from '../../assets/img-biz-logo.svg';
import { Button } from 'reactstrap';
import { login } from '../../actions';
import history from '../../common/history';
import { updateDocumentTitle, fetchCompanyAuth, logout } from '../../actions';
import { Translate, I18n } from 'react-redux-i18n';
import { TRIP_ACTIVITY, USER_MANAGEMENT, ROOT } from '../../constants/routes';
import { ADMIN, OVERSEA_ADMIN } from '../../constants/permission';
import { ERROR } from '../../constants/action-type';
import LoginErrorDialog from '../../components/LoginErrorDialog';
import Blocker from '../../components/Blocker';
import RingSpinner from '../../components/Spinner/Ring';
import './login-page.scss';
import { useState } from 'react';


const { REACT_APP_CLIENT_ID } = process.env;

const Login = () => {
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector(state => state.account);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [client, setClient] = useState(undefined);

    const handleLoggedInFailure = useCallback(() => {
        setOpen(true);
    }, []);

    const handleLogin = useCallback((res) => {
        dispatch(login(res)).catch(() => {
            handleLoggedInFailure();
        });
    }, [dispatch, handleLoggedInFailure]);

    useEffect(() => {
        if (window.google) {
            const userClient = window.google.accounts.oauth2.initCodeClient({
                client_id: REACT_APP_CLIENT_ID,
                scope: 'https://www.googleapis.com/auth/userinfo.profile ' +
                'https://www.googleapis.com/auth/userinfo.email',
                ux_mode: 'popup',
                callback: handleLogin
            });
            setClient(userClient);
        }
    }, [handleLogin]);

    useEffect(() => {
        let timer;
        dispatch(updateDocumentTitle(I18n.t('login')));
        dispatch(fetchCompanyAuth())
            .then((response) => {
                if (response.type !== ERROR) {
                    const { data } = response;
                    const { role } = data;
                    if (role === ADMIN || role === OVERSEA_ADMIN) {
                        history.push(TRIP_ACTIVITY);
                    }
                    else {
                        history.push(USER_MANAGEMENT);
                    }
                }
                else {
                    if (response.error.message === 'Not Login Yet') {
                        dispatch(logout());
                        history.push(ROOT);
                    }
                    else {
                        history.push(TRIP_ACTIVITY);
                    }
                }
                timer = setTimeout(() => {
                    setIsLoading(false);
                }, 800);
            });
        return () => {
            clearTimeout(timer);
        };
    }, [dispatch, isLoggedIn, isLoading]);


    return (
        <>
            {
                isLoading ? (
                    <Blocker className="login-loading">
                        <RingSpinner />
                    </Blocker>
                ) : (
                    <div className="login-page">
                        <BusinessTitle className="business-title" />
                        { client && (
                            <Button color="primary" className="btn-google-login" onClick={ () => client.requestCode() }>
                                <Translate value="login" />
                            </Button>
                        ) }
                        <LoginErrorDialog
                            title={ I18n.t('error.error') }
                            message={ I18n.t('invalidAccountLoginError') }
                            isOpen={ open }
                            onClose={ () => setOpen(false) }
                        />
                    </div>
                )
            }
        </>

    );
};

export default Login;