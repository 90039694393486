import { createBusinessApiInstanceWithToken } from '../util';
const file = {
    fetchUserFile: () => {
        return createBusinessApiInstanceWithToken().get('/users/files');
    },

    importUserFile: (file, type) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('import_type', type);
        return createBusinessApiInstanceWithToken({
            'Content-Type': 'multipart/form-data'
        }).post('/users/files', formData );
    }
};

export default file;