import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Input from '../Form/Input';

import './search-field.scss';

const SearchField = ({
    placeholder,
    type,
    onSubmit,
    onChange,
    onFocus,
    onBlur,
    value,
    tag,
    autoFocus,
    stopTriggerByEnter,
    inputRef
}) => {
    const elWrapper = useRef(null);

    const sendRequest = () => {
        onSubmit();
    };

    const handleSubmit = e => {
        e.preventDefault();
        sendRequest();
    };

    const handleClick = e => {
        if (stopTriggerByEnter) {
            e.preventDefault();
        }

        if (tag !== 'form') {
            sendRequest();
        }
    };

    const handleInputClick = e => {
        e.preventDefault();
    };

    const handleChange = e => {
        onChange(e);
    };

    const handleKeyDown = e => {
        let stop = (stopTriggerByEnter && e.keyCode === 13);

        if (stop) {
            e.preventDefault();
        }

        return !stop;
    };

    const Wrapper = tag;
    return (
        <Wrapper ref={ elWrapper } className="search-field" onSubmit={ handleSubmit }>
            <Input
                type={ type }
                preventEnter={ false }
                name="search-text"
                placeholder={ placeholder }
                autoComplete="off"
                onClick={ handleInputClick }
                onChange={ handleChange }
                onFocus={ onFocus }
                onBlur={ onBlur }
                onKeyDown={ handleKeyDown }
                value={ value }
                autoFocus={ autoFocus }
            />
            <button type="submit" className="go-search" onClick={ handleClick }>
                <span className="go-search-icon btn-icon" />
            </button>
        </Wrapper>
    );
};

SearchField.propTypes = {
    placeholder: PropTypes.string,
    type: PropTypes.string,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.node,
    tag: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.func,
    ]),
    autoFocus: PropTypes.bool,
    stopTriggerByEnter: PropTypes.bool,
    inputRef: PropTypes.shape({}),
};

SearchField.defaultProps = {
    placeholder: '',
    type: 'text',
    onSubmit: () => { },
    onChange: () => { },
    onFocus: () => { },
    onBlur: () => { },
    value: undefined,
    tag: 'form',
    autoFocus: false,
    stopTriggerByEnter: false,
    inputRef: undefined,
};

export default SearchField;