import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import serialize from 'form-serialize';
import RingSpinner from '../Spinner/Ring';

import './form.scss';

class CustomForm extends Component {
    static propTypes = {
        className: PropTypes.string,
        inline: PropTypes.bool,
        inProgress: PropTypes.bool,
        innerRef: PropTypes.oneOfType([
            PropTypes.shape(),
            PropTypes.node,
        ]),
        onSubmit: PropTypes.func,
        onInvalid: PropTypes.func,
        tag: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ]),
    };

    static defaultProps = {
        className: undefined,
        inline: false,
        inProgress: false,
        innerRef: undefined,
        onSubmit: () => {},
        onInvalid: () => {},
        tag: 'form',
    };

    constructor(props) {
        super(props);

        const { innerRef } = props;

        this.elForm = innerRef || React.createRef();
    }

    handleInvalid = e => {
        e.preventDefault();

        const { onInvalid } = this.props;
        onInvalid(e);
    };

    handleSubmit = e => {
        e.preventDefault();

        const { onSubmit } = this.props;

        e.formData = serialize(e.currentTarget, { hash: true });

        onSubmit(e);
    };

    renderInProgress() {
        const { inProgress } = this.props;

        return (
            inProgress ? (
                <div className="light-blocker">
                    <RingSpinner />
                </div>
            ) : null
        );
    }

    render() {
        const { className, inline, tag, children } = this.props;
        const formClass = classNames({
            'form': true,
            'inline-form': inline,
            [className]: className,
        });
        const CustomTag = tag || 'form';

        return (
            <CustomTag
                ref={ this.elForm }
                className={ formClass }
                onSubmit={ this.handleSubmit }
                onInvalid={ this.handleInvalid }
            >
                { children }

                { this.renderInProgress() }
            </CustomTag>
        );
    }
}

export default CustomForm;
