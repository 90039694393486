const datetimeFormat = {
    'date': 'YYYY-MM-DD',
    'time': 'HH:mm:ss',
    'long': 'YYYY-MM-DD HH:mm:ss',
    'utc': 'YYYY-MM-DDTHH:mm:00',
};
const lang = {
    'lang': '中文',
    'business_management': 'GoShare for Business',
    'login': '登入',
    'logout': '登出',
    'export': '匯出',
    'exporting': '匯出中',
    'export_finish': '正在準備您匯出的資料，完成後我們將透過電子信箱寄給您下載連結。',
    'import': '匯入',
    'import_success': '匯入成功',
    'cancel': '取消',
    'from': '起',
    'to': '迄',
    'days': '天',
    'duration': '騎乘時間',
    'duration-hr': '%{hr} 小時',
    'duration-min': '%{min} 分',
    'duration-sec': '%{sec} 秒',
    'email': '電子信箱',
    'name': '姓名',
    'ok': '好',
    'charged': '總計',
    'payment_status': '付款狀態',
    'save': '儲存',
    'apply': '套用',
    'choose_files': '選擇檔案',
    'no_file': '未選擇任何檔案',
    'datetime_format': datetimeFormat,
    'total_count': '總筆數',
    'last_udpated': '更新時間',
    'delete': '刪除',
    'file_size_3MB_warning': '檔案大小不得超過 3 MB',
    'invalidAccountLoginError': '帳號或密碼有誤，請重新輸入',
    'empty_row': '目前沒有任何資料呦！',
    'record_periods': '查詢期間',
    'plate_no': '車牌號碼',
    'please_login': '請再次登入',
    'please_login_message': (
        '為了維護您的工作安全，連線階段已過期。' +
        '請重新整理瀏覽器, 或點擊登入'
    ),
};

export default lang;
