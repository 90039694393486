import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { Button } from 'reactstrap';
import Modal from '../Modal';
import './error-message.scss';

const LoginErrorDialg = ({ isOpen, title, message, onClose }) => {
    return (
        <React.Fragment>
            { isOpen && (
                <Modal
                    title={ title }
                    className="popup-error-message"
                    onClose={ onClose }
                >
                    <p className="error">
                        <span className="icon fas fa-exclamation-circle" />
                        <span>{ message }</span>
                    </p>
                    <Button outline onClick={ onClose }>
                        <Translate value="ok" />
                    </Button>
                </Modal>
            )
            }
        </React.Fragment>
    );
};

LoginErrorDialg.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
};

LoginErrorDialg.defaultProps = {
    onClose: () => {},
    title: '',
    message: '',
};

export default LoginErrorDialg;