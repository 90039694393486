import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { v4 as uuid } from 'uuid';


const InsertedTranslate = ({ value, children, replacement }) => {
    const string = I18n.t(value);
    const segments = replacement ? string.split(`%{${ replacement }}`) : [string];
    const list = segments.reduce((accumulator, currentValue, currentIndex) => {
        const insteredItem = (currentIndex < segments.length - 1) ? [ children ] : [];
        return [
            ...accumulator,
            (<span>{ currentValue }</span>),
            ...insteredItem,
        ];
    }, []);

    return (
        <>
            {
                list.map(item => <React.Fragment key={ uuid() }>{ item }</React.Fragment>)
            }
        </>
    );
};

InsertedTranslate.propTypes = {
    value: PropTypes.string.isRequired,
    replacement: PropTypes.string,
    children: PropTypes.node,
};

InsertedTranslate.defaultProps = {
    replacement: undefined,
    children: undefined,
};

export default InsertedTranslate;