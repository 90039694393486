import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import {
    PAYMENT_STATUS_SUCCESS,
    PAYMENT_STATUS_FAIL,
    PAYMENT_STATUS_UNPAID,
    PAYMENT_STATUS_PENDING,
} from '../../constants/trip';

export const getPaymentStatusOptions = () => [
    PAYMENT_STATUS_SUCCESS,
    PAYMENT_STATUS_FAIL,
    PAYMENT_STATUS_UNPAID,
    PAYMENT_STATUS_PENDING,
].map(
    value => ({
        text: I18n.t(`trip.payment_status_map.${ value }`),
        value,
    })
);
const Component = ({ status, className, badge }) => {
    let el;
    let color = 'secondary';
    const colorMap = {
        [PAYMENT_STATUS_UNPAID]: 'warning',
        [PAYMENT_STATUS_SUCCESS]: 'success',
        [PAYMENT_STATUS_FAIL]: 'danger',
        [PAYMENT_STATUS_PENDING]: 'secondary',
    };

    switch (status) {
    case PAYMENT_STATUS_UNPAID:
    case PAYMENT_STATUS_SUCCESS:
    case PAYMENT_STATUS_FAIL:
    case PAYMENT_STATUS_PENDING:
        el = <Translate value={ `trip.payment_status_map.${ status }` } />;
        color = colorMap[status];
        break;
    default:
        el = <span>--</span>;
    }

    if (badge) {
        el = (
            <Badge color={ color } className={ className }>
                { el }
            </Badge>
        );
    }

    return el;
};

Component.propTypes = {
    status: PropTypes.number,
    className: PropTypes.string,
    badge: PropTypes.bool,
};

Component.defaultProps = {
    status: -1,
    className: '',
    badge: false,
};

export default Component;
