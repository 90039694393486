import React from 'react';
import PropTypes from 'prop-types';
import { OVERSEA } from '../../constants/permission';
import useRegion from '../../hooks/useRegion';
const RegionFeature = ({
    permit,
    showBy,
    hideBy,
    children,
}) => {
    const checkShowOrHide = (showBy, hideBy) => {
        let show = true;
        if (typeof showBy === 'boolean') {
            show = showBy;
        }

        if (typeof hideBy === 'boolean') {
            show = !hideBy;
        }

        return show;
    };
    const { isPermitted, regionLoaded } = useRegion(permit);
    const oneOfUndefined = !((typeof showBy === 'boolean' && typeof hideBy === 'boolean') ||
                            (showBy === undefined && hideBy === undefined));
    // will not affect UI for user with no OVERSEA permit
    const showContent = checkShowOrHide(showBy, hideBy);
    return (
        <>
            {
                regionLoaded ? (
                    (isPermitted && oneOfUndefined) ? (
                        showContent ? children : null
                    ) : children
                ) : null
            }

        </>
    );
};

RegionFeature.propTypes = {
    permit: PropTypes.number,
    showBy: PropTypes.bool,
    hideBy: PropTypes.bool,
};
RegionFeature.defaultProps = {
    permit: OVERSEA,
    // pass permit and show
    showBy: undefined,
    // pass permit and hide
    hideBy: undefined,
};

export default RegionFeature;