import React from 'react';
import PropTypes from 'prop-types';
import { Fade, Button, CardBody, Card, Form } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
import Blocker from '../../components/Blocker';
import FlexGroup from '../../components/FlexGroup';
import classNames from 'classnames';
import { ReactComponent as Arrow } from '../../assets/form-dropdown-arrow-focus.svg';
import './filter.scss';

class Filter extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        errorMessage: PropTypes.string,
        children: PropTypes.node.isRequired,
        value: PropTypes.string,
        open: PropTypes.bool,
        applied: PropTypes.bool,
        onToggle: PropTypes.func,
        onReset: PropTypes.func,
        onApply: PropTypes.func,
    };

    static defaultProps = {
        className: undefined,
        errorMessage: undefined,
        value: undefined,
        open: false,
        applied: false,
        onToggle: () => {},
        onReset: () => {},
        onApply: () => {},
    };

    handleReset = e => {
        e.stopPropagation();
        const { onReset } = this.props;
        onReset();
    };

    handleSubmit = e => {
        e.preventDefault();
        e.currentTarget.reportValidity();
        const { onApply } = this.props;
        onApply();
    };

    handleClickOutside = e => {
        e.stopPropagation();
        const { onToggle } = this.props;

        onToggle();
    };

    render() {
        const { className, errorMessage, open, value, applied, children, onToggle } = this.props;
        const handleReset = this.handleReset;
        const StatusIcon = ({ applied, open }) => {
            const cls = classNames({
                arrow: true,
                up: open
            });
            let statusEl = (<Arrow className={ cls } />);
            if (applied) {
                statusEl = (<span className="fas fa-times" onClick={ handleReset } />);
            }

            return statusEl;
        };
        const filterStyle = classNames({
            'custom-filter': true,
            [className]: !!className,
        });
        const blockerStyle = classNames({
            'custom-filter-wrap': true,
            'blocker': true,
            'hide': !open,
        });

        return (
            <div className={ filterStyle }>
                <Blocker className={ blockerStyle } onClick={ this.handleClickOutside } />
                <Button className={ `btn-filter ${ applied ? 'applied' : '' }` } onClick={ onToggle }>
                    <span className="filter-display-text">{ value }</span>
                    <StatusIcon applied={ applied } open={ open } />
                </Button>
                <Fade className="collasible-panel" in={ open }>
                    <Card>
                        <CardBody>
                            <Form className="content" onSubmit={ this.handleSubmit }>
                                { children }
                                <FlexGroup end className="button-group">
                                    <span className="error">{ errorMessage }</span>
                                    <Button color="primary" type="submit" disabled={ !!errorMessage }>
                                        <Translate value="apply" />
                                    </Button>
                                </FlexGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </Fade>
            </div>
        );
    }
}
export default Filter;
