import {
    ERROR_CODE_0,
    ERROR_CODE_2000,
    ERROR_CODE_2001,
    ERROR_CODE_2004,
    ERROR_CODE_2005,
    ERROR_CODE_2006,
    ERROR_CODE_2007,
    ERROR_CODE_2008,
    ERROR_CODE_2009,
    ERROR_CODE_2010,
    ERROR_CODE_3000,
    ERROR_CODE_3001
} from '../../../constants/error';

const lang = {
    'error': '哎呀！',
    [`error_${ERROR_CODE_0}`]: '發生錯誤，請稍後再試',
    [`error_${ERROR_CODE_2000}`]: '電子信箱不得為空白或格式錯誤',
    [`error_${ERROR_CODE_2001}`]: ' 已存在或重複出現於檔案中',
    [`error_${ERROR_CODE_2004}`]: '姓名、電子信箱、群組名稱皆不得為空白',
    [`error_${ERROR_CODE_2005}`]: '姓名、電子信箱、群組名稱皆不得為空白',
    [`error_${ERROR_CODE_2006}`]: '姓名、電子信箱、群組名稱皆不得為空白',
    [`error_${ERROR_CODE_2007}`]: ' 已存在於其他公司的 GoShare for Business 企業方案會員名單中',
    [`error_${ERROR_CODE_2008}`]: '姓名、電子信箱、群組名稱皆不得為空白',
    [`error_${ERROR_CODE_2009}`]: '欄位名稱不正確，請與範本一致',
    [`error_${ERROR_CODE_2010}`]: '會員電子信箱僅能為企業專屬網域電子信箱',
    [`error_${ERROR_CODE_3000}`]: '群組名稱已存在',
    [`error_${ERROR_CODE_3001}`]: '只有在群組內沒有任何會員時方可刪除群組',
};
export default lang;