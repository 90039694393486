import {
    OPEN_SYSTEM_DIALOG,
    CLOSE_SYSTEM_DIALOG,
} from '../constants/action-type';

export const openSystemDialog = (title, message) => (dispatch) => {
    dispatch({
        type: OPEN_SYSTEM_DIALOG,
        options: {
            title,
            message,
        },
    });
};

export const closeSystemDialog = () => (dispatch) => {
    dispatch({
        type: CLOSE_SYSTEM_DIALOG,
    });
};