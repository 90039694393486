import {
    FETCH_USER_LIST,
    PATCH_USER,
    CREATE_USER,
    CLEAR_USER_LIST,
    FETCH_DOMAIN,
} from '../constants/action-type';
import { asyncInterface } from './util';
import api from '../api';

export const fetchUserList = (params = {}) => (dispatch) => {
    const fetchFunction = () => api.fetchUserList(params)();
    return asyncInterface({
        asyncAction: fetchFunction,
        type: FETCH_USER_LIST,
        dispatch,
    });
};

export const patchUser = (editArray = []) => (dispatch) => {
    return asyncInterface({
        asyncAction: api.patchUser(editArray),
        type: PATCH_USER,
        dispatch,
    });
};

export const createUser = (params = {}) => (dispatch) => {
    return asyncInterface({
        asyncAction: api.createUser(params),
        type: CREATE_USER,
        dispatch,
    });
};

export const fetchDomain = () => (dispatch) => {
    const fetchFunction = () => api.fetchDomain()();
    return asyncInterface({
        asyncAction: fetchFunction,
        type: FETCH_DOMAIN,
        dispatch,
    });
};

export const clearUserList = () => {
    return {
        type: CLEAR_USER_LIST,
    };
};