import {
    LOGIN,
    LOGOUT,
    REFRESH_TOKEN,
    GET_COMPANY_AUTH,
    CLEAR_PERMISSION,
} from '../constants/action-type';
import account from '../helpers/account';
import { asyncInterface } from './util';
import api from '../api';

export const login = ({ code }) => dispatch => {
    return api.fetchTokenByCode(code)
        .then(({ data }) => data)
        .then(profile => {
            const { id_token } = profile;
            return api.fetchCompanyAuth(id_token)().then(() => profile);
        })
        .then(payload => {
            return Promise.resolve(dispatch({
                type: LOGIN,
                payload: payload,
            }));
        });
};

export const logout = () => dispatch => {
    return api.deleteCookie()
        .then(() => {
            dispatch(clearPermission());
            return Promise.resolve(dispatch({
                type: LOGOUT,
            }));
        });
};

export const fetchCompanyAuth = () => (dispatch) => {
    const fetchFunction = () => api.fetchCompanyAuth()();
    return asyncInterface({
        asyncAction: fetchFunction,
        type: GET_COMPANY_AUTH,
        dispatch,
    });
};

export const clearPermission = () => {
    return {
        type: CLEAR_PERMISSION,
    };
};

export const fetchNewToken = () => dispatch => {
    return api.fetchNewToken().then(({ data }) => {
        const { profile, expires_in, id_token } = data;
        const res = { ...profile, expires_in };
        account.setID(id_token);
        account.set(res, true);
        account.setProfile(res);
        account.setExpiresAt(res);
        return Promise.resolve(dispatch({
            type: REFRESH_TOKEN,
            data,
        }));
    });
};