import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-redux-i18n';
import { Label, Input } from 'reactstrap';
import {
    TYPE_RADIO,
} from '../Input/input-type';

const RadioGroup = (props) => {
    const { selected, required, caption, value, name, onChange, disabled, withCaption,  ...rest } = props;
    const realSelected = Array.isArray(selected) ? [...selected] : [selected];

    const getRequired = () => {
        return selected.length === 0 && required;
    };

    const captionClass = classNames({
        caption: true,
        required: required,
    });
    const wrapperClass = classNames({
        'control-group': true,
        'disabled': disabled,
    });
    const controlClass = classNames({
        'group-selection': true,
        'control-wrapper': true,
        'full-width': !withCaption,
    });

    return (
        <div className={ wrapperClass }>
            {
                withCaption ?
                    (
                        <Translate className={ captionClass } value={ caption } tag="h5" />
                    ) :
                    null
            }
            <div className={ controlClass }>
                {
                    value.map((item, i) => {
                        const checked = realSelected.indexOf(item.value) > -1;
                        const el = (
                            <>
                                <Label className="radio" check>
                                    <Input
                                        { ...rest }
                                        type={ TYPE_RADIO }
                                        name={ name }
                                        required={ i === 0 ? getRequired() : false }
                                        value={ item.value }
                                        onChange={ () => {
                                            onChange(item.value);
                                        } }
                                        checked={ checked }
                                    />
                                    <Translate className="radio-label" value={ item.name } />
                                    <span className="separation" />
                                    <Translate className="label-description" value={ item.description } />
                                </Label>
                            </>
                        );
                        return (
                            <div key={ item.value }>
                                { el }
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

RadioGroup.propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({
        // translation key
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.bool
        ]),
    })).isRequired,
    name: PropTypes.string,
    selected: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.bool)
    ]),
    onChange: PropTypes.func,
    required: PropTypes.bool,
    caption: PropTypes.string,
    disabled: PropTypes.bool,
    withCaption: PropTypes.bool,
};

RadioGroup.defaultProps = {
    name: '',
    caption: '',
    selected: [],
    onChange: () => {},
    required: false,
    disabled: false,
    withCaption: true,
};

export default RadioGroup;