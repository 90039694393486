import axios from 'axios';
import account from '../helpers/account';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import qs from 'qs';

const { REACT_APP_ENV, REACT_APP_API_BASE_URL } = process.env;

const proxyWrapper = instance => (
    new Proxy(instance, {
        get: (target, name) => {
            const requestMethods = ['get', 'post', 'delete', 'patch', 'put'];
            const action = target[name];

            if (requestMethods.indexOf(name) > -1) {
                return (...props) => action.bind(target, ...props);
            }
            else {
                return action;
            }
        },
    })
);

export const createInstance = (basePath, headers = {},  xsrfConfig = {}) => {
    return axios.create({
        headers,
        baseURL: basePath,
        withCredentials: true,
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
        ...xsrfConfig,
    });
};

export const createBusinessApiInstanceWithToken = (headers = {}) => {
    const realHeaders = {
        ...headers,
        'Authorization': getAPIkey(),
        'Content-Type': headers['Content-Type'] ? headers['Content-Type'] : 'application/json',
    };
    return createBusinessApiInstance(realHeaders);
};

export const createAmazonApiInstance = (basePath, headers = {}) => {
    const instance = createInstance(basePath, headers);
    instance.defaults.headers.common = {};

    return instance;
};

export const createBusinessApiInstance = (headers = {}) => {
    const baseUrl = REACT_APP_API_BASE_URL;

    if (!baseUrl) {
        throw new Error(`${ REACT_APP_ENV } is not expected environment`);
    }

    const xsrfToken = Cookies.get('XSRF-TOKEN');

    if (!xsrfToken) {
        resetXSRFToken();
    }

    const xsrfConfig = {
        xsrfCookieName: 'XSRF-TOKEN',
        xsrfHeaderName: 'X-XSRF-TOKEN',
    };

    const instance = createInstance(
        baseUrl,
        headers,
        xsrfConfig,
    );

    return proxyWrapper(instance);
};

const resetXSRFToken = () => {
    const domain = REACT_APP_API_BASE_URL;

    const xsrfToken = uuidv4();
    Cookies.set('XSRF-TOKEN', xsrfToken, { domain });
};

export const createOAuthInstance = () => {
    const baseUrl = `${ REACT_APP_API_BASE_URL }/business_users/google_auth/`;
    if (!baseUrl) {
        throw new Error(`${ REACT_APP_ENV } is not expected environment`);
    }

    return createInstance(baseUrl);
};

export const getAPIkey = () => {
    const id_token = account.getID() || {};
    return `Bearer ${ id_token || '' }`;
};
