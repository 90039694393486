import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-redux-i18n';
import serialize from 'form-serialize';
import Modal from '../../../components/Modal';
import ButtonGroup from '../../../components/ButtonGroup';
import Input from '../../../components/Form/Input';
import Button from '../../../components/Form/Button';
import DropDownList from '../../../components/Form/DropDownList';
import RingSpinner from '../../../components/Spinner/Ring';
import Blocker from '../../../components/Blocker';
import { createUser, patchUser } from '../../../actions';

import './user-action.scss';
import { useSelector, useDispatch } from 'react-redux';
import FlexGroup from '../../../components/FlexGroup';

const UserAction = ({ onClose, onAction, userInfo, innerRef }) => {
    const dispatch = useDispatch();

    const [name, setName] = useState(userInfo ? userInfo.name : '');
    const [email, setEmail] = useState(userInfo ? userInfo.email : '');

    const { groupList } = useSelector(state => state.group);
    const { domainList } = useSelector(state => state.user);
    const { asyncStart } = useSelector(state => state.asyncHandler);

    useEffect(() => {
        if (innerRef) {
            if (userInfo !== null) {
                innerRef['name'].current.focus();
            }
            else {
                innerRef['email'].current.focus();
            }
        }
    }, [innerRef, userInfo]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let formData = serialize(e.currentTarget, { hash: true });
        const { userName, userEmail, emailDomain, userGroup } = formData;
        let submitObject = {
            name: userName,
            email: domainList.length === 0 || userInfo !== null ?
                userEmail : `${userEmail}@${domainList[emailDomain].name}`,
            group_id: userGroup
        };
        if (userInfo !== null) {
            submitObject = { ...submitObject, id: userInfo.id };
            if (userInfo.email === submitObject.email) {
                delete submitObject.email;
            }
            await dispatch(patchUser([submitObject]));
        }
        else {
            await dispatch(createUser(submitObject));
        }
        onAction({
            page: 1,
        });
        onClose();
    };

    const renderSpinner = () => {
        const spinnerStyle = classNames({
            'spinner-container': true,
            hide: !asyncStart,
        });
        const Spinner = () => (
            <Blocker className={ spinnerStyle }>
                <RingSpinner />
            </Blocker>
        );
        return <Spinner />;
    };

    return (
        <Modal
            className="user-action"
            title={ userInfo ? I18n.t('user.edit') : I18n.t('user.new') }
            onClose={ onClose }
        >
            <form className="form section" onSubmit={ handleSubmit }>
                <FlexGroup className={ `${domainList.length !== 0 && 'email'}` }>
                    <Input
                        type={ `${domainList.length !== 0 ? 'text' : 'email'}` }
                        innerRef={ innerRef['email'] }
                        name="userEmail"
                        caption="user.email"
                        value={ email }
                        onChange={ (e) => {
                            setEmail(e);
                        } }
                        autoComplete="off"
                        label={ userInfo }
                        required
                    />
                    { (userInfo === null && domainList.length !== 0) &&
                    (
                        <>
                            <span className="email-at">@</span>
                            <DropDownList
                                name="emailDomain"
                                className="email-domain"
                                value={ domainList }
                            />
                        </>
                    ) }
                </FlexGroup>
                <Input
                    name="userName"
                    innerRef={ innerRef['name'] }
                    caption="user.name"
                    value={ name }
                    onChange={ (e) => {
                        setName(e);
                    } }
                    autoComplete="off"
                    required
                />
                <DropDownList
                    name="userGroup"
                    caption="user.group"
                    value={ groupList }
                    selected={ userInfo && userInfo.group_name ?
                        groupList.filter(item => item.name === userInfo.group_name)[0].id :
                        '' }
                    required
                />
                <ButtonGroup className="modal-button-group">
                    <Button
                        type="button"
                        onClick={ onClose }
                    >
                        <Translate value="cancel" />
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                    >
                        <Translate value="save" />
                    </Button>
                </ButtonGroup>
            </form>
            { renderSpinner() }
        </Modal>
    );
};

UserAction.propTypes = {
    userInfo: PropTypes.shape(),
    innerRef: PropTypes.shape(),
    onClose: PropTypes.func,
    onAction: PropTypes.func,
};

UserAction.defaultProps = {
    userInfo: null,
    innerRef: undefined,
    onClose: () => {},
    onAction: () => {},
};

export default UserAction;