import {
    GROUP,
} from '../../../constants/routes';

import Routes from './routes';

const lang = {
    'document_title': Routes[GROUP],
    'description': 'Description',
    'count': 'Number of users',
    'group': 'Group Name',
    'edit': 'Edit',
    'create': 'Create',
    'remove': 'Delete Group(s)',
    'confirm-remove': 'Are you sure you want to delete the following group(s):',
};

export default lang;