import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { OVERSEA } from '../constants/permission';

export default function useRegion(permit) {
    const accountReducer = useSelector(state => state.account);
    const { region } = accountReducer;
    const [userRegion, setUserRegion] = useState(region);

    useEffect(() => {
        setUserRegion(region);
    }, [region]);

    return {
        isOversea: !!(userRegion === OVERSEA),
        isPermitted: !!(userRegion === permit),
        regionLoaded: !!(userRegion !== null),
    };
};
