import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Cell from '../../components/ListView/Cell';
import UserVerStatus from '../../components/UserVerStatus/UserVerStatus';

const ListRow = ({ columns, data, onClick }) => {
    const { groupList } = useSelector(state => state.group);

    const getGroupName = (value) => {
        let mappingName = { name: '' };
        if (groupList.length !== 0 && value) {
            mappingName = groupList.find(({ name, id }) => {
                return name === value;
            });
        }
        return mappingName ? mappingName.name : '';
    };

    const renderField = (renderData, key, width) => {
        let el;
        const value = typeof renderData[key] === 'string' && renderData[key].trim() === '' ?
            ' ' : renderData[key];

        switch (key) {
        case 'name':
            el = (
                <span
                    className="name"
                    onClick={ () => onClick(renderData) }
                >
                    { value }
                </span>
            );
            break;
        case 'verification_status':
            el = (<UserVerStatus status={ value } />);
            break;
        case 'group_name':
            el = ( <span>{ getGroupName(value) }</span> );
            break;
        default:
            el = ( <span>{ value }</span> );
            break;
        }

        return (
            <Cell
                key={ `user-${ renderData.id }-${ key }` }
                name={ key }
                style={ { width } }
            >
                { el }
            </Cell>
        );
    };

    return columns.map(({ key, width }, i) => {
        return renderField(data, key, width);
    });
};

ListRow.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        user_name: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
    }).isRequired,
    onClick: PropTypes.func,
};

ListRow.defaultProps = {
    onClick: () => {},
};

export default ListRow;