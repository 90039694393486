import {
    OPEN_SYSTEM_DIALOG,
    CLOSE_SYSTEM_DIALOG,
} from '../constants/action-type';

const initalState = {
    isOpen: false,
    title: 'default',
    children: null,
};

const reducer = (state = initalState, action) => {
    switch (action.type) {
    case OPEN_SYSTEM_DIALOG:
        return {
            ...state,
            isOpen: true,
            title: action.options.title,
            message: action.options.message,
        };
    case CLOSE_SYSTEM_DIALOG:
        return { ...state, isOpen: false };
    default:
        return state;
    };
};

export default reducer;
