import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import classNames from 'classnames';
import preferenceHandler from '../../helpers/preference-handler';
import Arrow from '../../assets/form-dropdown-arrow-focus.svg';
import './page-size-select.scss';

const PAGE_SIZE_KEY = 'page-size';
const pageSizeOptions = [
    { value: 50, label: 50 },
    { value: 100, label: 100, idDefault: true },
    { value: 200, label: 200 },
];
const selectStyles = {
    control: () => ({
        alignItems: 'center',
        backgroundColor: '#f3f3f3',
        borderRadius: '20px',
        cursor: 'default',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        minHeight: '40px',
        outline: '0 !important',
        position: 'relative',
        transition: 'all 100ms',
        boxSizing: 'border-box',
    }),
    input: () => ({
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    }),
    valueContainer: () => ({
        alignItems: 'center',
        display: 'flex',
        flex: '1',
        flexWrap: 'wrap',
        padding: '4px 8px 4px 12px',
        position: 'relative',
        overflow: 'hidden',
        boxSizing: 'content-box',
    }),
    singleValue: () => ({
        color: 'hsl(0,0%,20%)',
        marginLeft: '2px',
        marginRight: '-2px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        minWidth: '32px',
    }),
    indicatorsContainer: () => ({
        alignItems: 'center',
        alignSelf: 'stretch',
        display: 'flex',
        flexShrink: '0',
        boxSizing: 'border-box',
        paddingRight: '8px',
    }),
    indicatorSeparator: () => ({
        width: '0',
    }),
};

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator { ...props }>
            <img src={ Arrow } alt="" />
        </components.DropdownIndicator>
    );
};

class PageSizeSelect extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        className: PropTypes.string,
    };

    static defaultProps = {
        onChange: () => {},
        className: '',
    };

    constructor(props) {
        super(props);

        this.state = {
            selectedPageSize: getDefaultPageSize(),
        };
    }

    handleChange = option => {
        const { onChange } = this.props;
        preferenceHandler.set(PAGE_SIZE_KEY, option);

        this.setState({
            selectedPageSize: option,
        });

        onChange(option);
    };

    render() {
        const { className } = this.props;
        const { selectedPageSize } = this.state;
        const customStyle = classNames({
            'page-size-select-wrapper': true,
            [className]: className,
        });
        return (
            <div className={ customStyle }>
                <Select
                    className="page-size-select"
                    components={ { DropdownIndicator } }
                    styles={ selectStyles }
                    options={ pageSizeOptions }
                    value={ selectedPageSize }
                    onChange={ this.handleChange }
                />
            </div>
        );
    }
};
export default PageSizeSelect;

export const getDefaultPageSize = () => {
    const preferPageSize = preferenceHandler.get(PAGE_SIZE_KEY);
    return preferPageSize || pageSizeOptions.find(({ idDefault }) => idDefault);
};
