import {
    FETCH_GROUP,
    CREATE_GROUP,
    PATCH_GROUP,
    DELETE_GROUP,
    CLEAR_GROUP,
} from '../constants/action-type';
import { asyncInterface } from './util';
import api from '../api';

export const fetchGroup = (params = {}) => (dispatch) => {
    const fetchFunction = () => api.fetchGroup(params)();
    return asyncInterface({
        asyncAction: fetchFunction,
        type: FETCH_GROUP,
        dispatch,
    });
};

export const patchGroup = (id, params) => (dispatch) => {
    return asyncInterface({
        asyncAction: api.patchGroup(id, params),
        type: PATCH_GROUP,
        dispatch,
    });
};

export const createGroup = (params) => (dispatch) => {
    return asyncInterface({
        asyncAction: api.createGroup(params),
        type: CREATE_GROUP,
        dispatch,
    });
};

export const deleteGroup = (deleteArray = []) => (dispatch) => {
    return asyncInterface({
        asyncAction: api.deleteGroup(deleteArray),
        type: DELETE_GROUP,
        dispatch,
    });
};

export const clearGroup = () => {
    return {
        type: CLEAR_GROUP,
    };
};