import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import FlexGroup from '../../components/FlexGroup';
import ListView from '../../components/ListView';
import ListRow from './ListRow';
import { Button } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
import Pagination from '../../components/Pagination';
import PageSizeSelect, { getDefaultPageSize } from '../../components/Pagination/PageSizeSelect';
import DeletePanel from '../../components/DeletePanel';
import GroupAction from './GroupAction';
import UpdateTime from '../../components/UpdateTime';
import RefreshButton from '../../components/RefreshButton';
import TotalCount from '../../components/TotalCount';
import { I18n } from 'react-redux-i18n';
import { headerConfig } from './ListHeader';
import { deleteGroup } from '../../actions';
import ButtonGroup from '../../components/ButtonGroup';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

const List = (props) => {
    const dispatch = useDispatch();
    const {
        onSelect,
        onAction,
        onChange,
        selectedIdList,
        list,
        pageSize,
        pageCount,
        pageIndex,
        onUncheckedAll,
    } = props;
    const [showDeletePanel, setShowDeletePanel] = useState(false);
    const [showGroupAction, setShowGroupAction] = useState(false);
    const [groupInfo, setGroupInfo] = useState(null);
    const innerRef = useRef(null);

    const { pageTotal } = useSelector(state => state.group);

    const setSelectById = (ids, selectAll) => {
        let updateGroupList = [...selectedIdList];

        if (selectAll) {
            updateGroupList = ids.length === list.length ? ids : [];
        }
        else {
            ids.forEach(id => {
                if (updateGroupList.indexOf(id) === -1) {
                    updateGroupList.push(id);
                }
                else {
                    const idx = updateGroupList.indexOf(id);
                    updateGroupList.splice(idx, 1);
                }
            });
        }

        onSelect(updateGroupList);
    };

    const handleListSort = ({ field, order }) => {
        let sort = null;
        if (field && order) {
            sort = `${ field },${ order }`;
        }
        onUncheckedAll();

        onChange({
            sort,
            page: 1,
        });
    };

    const handlePageSizeChange = selection => {
        onUncheckedAll();
        onChange({
            size: selection,
            page: 1,
        });
    };

    const handlePageSelect = page => {
        onUncheckedAll();
        onChange({
            page,
            size: pageSize,
        });
    };

    const toggleDeletePanel = () => {
        setShowDeletePanel(!showDeletePanel);
    };

    const toggleGroupAction = () => {
        setGroupInfo(null);
        setShowGroupAction(!showGroupAction);
    };

    const setGroupAction = (data) => {
        setShowGroupAction(true);
        setGroupInfo(data);
    };

    const handleDeleteSubmit = async (e) => {
        e.preventDefault();
        await dispatch(deleteGroup(selectedIdList));
        onUncheckedAll();
        onAction();
        toggleDeletePanel();
    };


    const ListItem = ({ columns, rowData }) => (
        <ListRow
            key={ `group-${ rowData.id }` }
            columns={ columns }
            data={ rowData }
            onClick={ setGroupAction }
        />
    );

    ListItem.propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        rowData: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    };

    return (
        <React.Fragment>
            <FlexGroup start wrap>
                <ButtonGroup start className="button-group-before-pagi">
                    <Button
                        className="btn-group"
                        color="primary"
                        type="button"
                        onClick={ () => {
                            setShowGroupAction(true);
                        } }
                    >
                        <Translate value="group.create" />
                    </Button>
                    <button
                        className="btn-delete btn-group"
                        disabled={ selectedIdList.length === 0 }
                        onClick={ toggleDeletePanel }
                        type="button"
                    >
                        <span className="btn-delete-icon btn-icon" />
                    </button>
                </ButtonGroup>
                <div className="pagination-control-sec">
                    <Pagination
                        page={ pageIndex }
                        total={ pageCount }
                        offset={ pageSize }
                        onSelect={ handlePageSelect }
                    />
                    <PageSizeSelect
                        onChange={ selection => handlePageSizeChange(selection.value) }
                    />
                </div>
                <div className="pagination-after-block">
                    <TotalCount count={ pageTotal } />
                    <UpdateTime time={ moment().format(I18n.t('datetime_format.long')) } />
                    <RefreshButton onClick={ onAction } />
                </div>
            </FlexGroup>
            <ListView
                className="list-view-bottom"
                isInitailResult={ !!(pageTotal === undefined) }
                list={ list }
                dataKey="id"
                renderListRow={ ListItem }
                onSelect={ setSelectById }
                onSort={ handleListSort }
                defaultSelect={ selectedIdList }
                header={ headerConfig }
                selectable
            />
            {
                showDeletePanel &&
                (
                    <DeletePanel
                        list={ list }
                        type="group"
                        title={ I18n.t('group.remove') }
                        description="group.confirm-remove"
                        selectedIdList={ selectedIdList }
                        onSubmit={ handleDeleteSubmit }
                        onAction={ onAction }
                        onClose={ toggleDeletePanel }
                    />
                )
            }
            {
                showGroupAction &&
                (
                    <GroupAction
                        innerRef={ innerRef }
                        groupInfo={ groupInfo }
                        onAction={ onAction }
                        onClose={ toggleGroupAction }
                    />
                )
            }
        </React.Fragment>
    );
};

List.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onSelect: PropTypes.func.isRequired,
    onAction: PropTypes.func,
    onUncheckedAll: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    pageIndex: PropTypes.number,
    pageCount: PropTypes.number,
    pageSize: PropTypes.number,
    selectedIdList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

List.defaultProps = {
    pageIndex: 0,
    pageCount: 0,
    pageSize: getDefaultPageSize().value,
    onAction: () => {},
    onUncheckedAll: () => {},
};

export default List;