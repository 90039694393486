import React, { useEffect, useCallback } from 'react';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import ActionHeader from '../../components/ActionHeader';
import { I18n } from 'react-redux-i18n';
import { updateDocumentTitle, fetchAllowance, logout } from '../../actions';
import EmptyListBlocker from '../../components/EmptyListBlocker';
import Allowance from './Allowance/Allowance';
import account from '../../helpers/account';
import history from '../../common/history';
import {
    USER_MANAGEMENT,
} from '../../constants/routes';
import permissionHandler from '../../helpers/permission-handler';
import {
    AUTH_VIEW_BUSINESS_PLAN, ADMIN, OVERSEA_ADMIN
} from '../../constants/permission';
import './billing.scss';

const Billing = () => {
    const dispatch = useDispatch();
    const { locale } = useSelector(state => state.i18n);
    const { general, vip } = useSelector(state => state.billing);
    const { permission, role } = useSelector(state => state.account);

    const fetchAllowanceByRole = useCallback(() => {
        switch (role) {
        case ADMIN:
        case OVERSEA_ADMIN:
            dispatch(fetchAllowance());
            break;
        default:
            break;
        }
    }, [dispatch, role]);

    useEffect(() => {
        if (permission.length !== 0) {
            if (!permissionHandler({ requiredList: [AUTH_VIEW_BUSINESS_PLAN] })) {
                if (!account.exists()) {
                    dispatch(logout());
                }
                else {
                    history.push(USER_MANAGEMENT);
                }
            }
        }
        fetchAllowanceByRole();
    }, [dispatch, permission, fetchAllowanceByRole]);

    useEffect(() => {
        dispatch(updateDocumentTitle(I18n.t('billing.document_title')));
    }, [ locale, dispatch ]);

    return (
        <div className="billing-list app-feature-wrapper">
            <ActionHeader title={ I18n.t('billing.document_title') } />
            {
                general === null && vip === null ? (
                    <EmptyListBlocker />
                ) : (
                    <React.Fragment>
                        <Allowance
                            item={ general }
                        />
                        <Allowance
                            isVip={ true }
                            item={ vip }
                        />
                    </React.Fragment>
                )
            }

        </div>
    );
};

export default Billing;