import React from 'react';
import PropTypes from 'prop-types';

const RefreshButton = ({ onClick, disabled }) => {
    return (
        <button className="btn-refresh" disabled={ disabled } onClick={ onClick } type="button">
            <span className="btn-refresh-icon btn-icon" />
        </button>
    );
};
RefreshButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};
RefreshButton.defaultProps = {
    onClick: () => { },
    disabled: false,
};

export default RefreshButton;