import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    toggleMenu,
    toggleMenuCategory,
    clearMenuCategoryActive,
} from '../../actions';
import { Translate } from 'react-redux-i18n';
import Blocker from '../Blocker';
import getHierarchicalMenu, { getCategoryByPathName } from './get-hierarchical-menu';
import packageInfo from '../../../package.json';

import './sidebar.scss';

const { version } = packageInfo;

const FORBID_SWITCH_CLASSNAME = 'sidebar-switch-forbid';

const MenuLink = ({ to, children, onClick }) => (
    <li className="menu-item" onClick={ onClick }>
        <NavLink exact className="menu-link" to={ to }>{ children }</NavLink>
    </li>
);
MenuLink.propTypes = {
    to: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};
MenuLink.defaultProps = {
    onClick: () => {},
};

const CategoryLink = ({ to, children, onClick }) => (
    <li className="menu-category" key={ to } onClick={ onClick }>
        <NavLink exact className="category category-link" to={ to }>{ children }</NavLink>
    </li>
);
CategoryLink.propTypes = {
    to: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};
CategoryLink.defaultProps = {
    onClick: () => {},
};

const Sidebar = (props) => {
    const { history } = props;
    const dispatch = useDispatch();
    const { menuOpen, menuCategoryOpen, menuCategoryActive } = useSelector(state => state.general);
    const { permission } = useSelector(state => state.account);
    const { pathname } = history.location;

    useEffect(() => {
        const category = getCategoryByPathName(pathname);
        dispatch(clearMenuCategoryActive());
        if (category) {
            dispatch(toggleMenuCategory(category, true, category));
        }

    }, [ pathname, dispatch ]);

    const handleCloseMenu = (e) => {
        const isCategory = !( (e.target.className).indexOf(FORBID_SWITCH_CLASSNAME) > -1 );

        if ( isCategory) {
            dispatch(toggleMenu());
        }
    };

    const getRoutes = () => {
        let routes = [];

        if (permission) {
            routes = getHierarchicalMenu(permission);
        }
        return routes;
    };

    const handleCategorySwitch = (category, switchStatus = !menuCategoryOpen[category], activeCategory = '') => (e) => {
        dispatch(toggleMenuCategory(category, switchStatus, activeCategory));

    };

    const handleCategoryLink = (e) => {
        dispatch(clearMenuCategoryActive());
    };

    const renderCategory = () => {
        const routes = getRoutes();

        return Object.keys(routes).map(category => {
            const categoryItem = routes[category].filter(item => item.showAsCategory);
            const menuStyles = classNames({
                'menu': true,
                'openMenu': menuCategoryOpen[category],
            });

            const categoryStyles = (category) => (classNames({
                'category': true,
                'isActive': !!(menuCategoryActive === category),
                [FORBID_SWITCH_CLASSNAME]: true,
            }));

            return categoryItem.length ? (
                <CategoryLink to={ categoryItem[0].route } key={ category } onClick={ handleCategoryLink }>
                    <Translate value={ `routes.${ categoryItem[0].route }` } />
                </CategoryLink>
            ) : (
                <li className="menu-category" key={ category }>
                    <h3 className={ categoryStyles(category) } onClick={ handleCategorySwitch(category) }>
                        <Translate value={ `routes.category_${ category }` } className={ FORBID_SWITCH_CLASSNAME } />
                    </h3>
                    <ul className={ menuStyles }>
                        { renderMenu(routes[category], category) }
                    </ul>
                </li>
            );
        });
    };

    const renderMenu = (routes, category) => {
        return routes.filter(item => !item.showAsCategory).map(({ route }) => (
            <MenuLink key={ route } to={ route } onClick={ handleCategorySwitch(category, true, category) }>
                <Translate value={ `routes.${ route }` } />
            </MenuLink>
        ));
    };
    const styles = classNames({
        'sidebar': true,
        'hide': !menuOpen,
    });
    const menuList = (
        <ul className="menu">
            { renderCategory() }
        </ul>
    );

    return (
        <Blocker className={ styles } onClick={ handleCloseMenu }>
            <aside onClick={ handleCloseMenu }>
                <menu>
                    { menuList }
                </menu>
                <p className="version">{ `v${ version }` }</p>
            </aside>
        </Blocker>
    );
};

export default Sidebar;
