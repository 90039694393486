import { FETCH_USER_LIST, CLEAR_USER_LIST, FETCH_DOMAIN } from '../constants/action-type';

const INITIAL_STATE = {
    userList: null,
    pageTotal: undefined,
    domainList: [],
};


const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case FETCH_USER_LIST:
        return {
            ...state,
            userList: action.data,
            pageTotal: action.data.total,
        };
    case CLEAR_USER_LIST:
        return {
            ...state,
            userList: [],
            pageTotal: undefined,
        };
    case FETCH_DOMAIN:
        const domains = action.data.domains.map((item, index) => ({
            id: index,
            name: item,
        }));
        return {
            ...state,
            domainList: domains,
        };
    default:
        return state;
    }
};
export default reducer;