import {
    GROUP_USER,
} from '../../../constants/routes-with-hierarchy';

import {
    TRIP_ACTIVITY,
    USER_MANAGEMENT,
    BILLING,
    GROUP,
} from '../../../constants/routes';

const lang = {
    [TRIP_ACTIVITY]: 'Trip History',
    [USER_MANAGEMENT]: 'User List',
    [GROUP]: 'Group List',
    [BILLING]: 'Plan',
    [`category_${GROUP_USER}`]: 'User Management',
};

export default lang;

