import store from '../store';
import account, { IS_LOGGED_IN } from '../helpers/account';

export const MATCH_ALL = 'MATCH_ALL';
export const ANY_ONE = 'ANY_ONE';

const permissionHandler = ({ requiredList, term = MATCH_ALL }) => {
    const auth = store.getState().account;
    const isLoggedIn = account.get(IS_LOGGED_IN);
    const { permission } = auth;

    let isAuthenticated = isLoggedIn && permission;

    if (isAuthenticated) {
        const plainAuthorities = permission.map(({ authority }) => authority);
        const checker = (term === MATCH_ALL ? 'every' : 'some');

        isAuthenticated = requiredList[checker](authority => plainAuthorities.indexOf(authority) > -1);
    }

    return isAuthenticated;
};

export default permissionHandler;