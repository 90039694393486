/**
 * Logged in account
 */
export const ACCOUNT_ITEM_KEY = 'currentUser';
export const IS_LOGGED_IN = 'isLoggedIn';
const account = {
    token: '',
    expires_at: null,
    profile: null,
    set: (res, isLoggin = true) => {
        localStorage.setItem(ACCOUNT_ITEM_KEY, JSON.stringify(res));
        localStorage.setItem(IS_LOGGED_IN, isLoggin);
    },

    get: (key = ACCOUNT_ITEM_KEY) => {
        return JSON.parse(localStorage.getItem(key));
    },

    setID: (id_token) => {
        account.token = id_token;
    },

    getID: () => {
        return account.token;
    },

    setExpiresAt: res => {
        if (res) {
            const { expires_in } = res;
            const expires_at = Date.now() + (Math.min(expires_in, 1800) * 1000);
            return account.expires_at = expires_at;
        }
        return account.expires_at = null;
    },


    getExpiresAt: () => {
        return account.expires_at;
    },

    setProfile: (res) => {
        const profile = JSON.stringify(res);
        account.profile = profile;
    },

    getProfile: () => {
        return JSON.parse(account.profile);
    },

    exists: () => {
        return !!account.get();
    },

    remove: () => {
        localStorage.removeItem(ACCOUNT_ITEM_KEY);
        localStorage.removeItem(IS_LOGGED_IN);
    },

    diff: () => {
        const inMemoryProfile = account.getProfile();
        const localStorageProfile = account.get();

        return inMemoryProfile?.email !== localStorageProfile?.email;
    },
};

export default account;
