import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input, Label } from 'reactstrap';
import { Translate, I18n } from 'react-redux-i18n';
import {
    TYPE_TEXT,
    TYPE_EMAIL,
} from './input-type';
import '../form.scss';

export * from './input-type';


const CustomInput = ({
    type,
    label,
    viewMode,
    preventEnter,
    placeholder,
    autoComplete,
    name,
    innerRef,
    caption,
    required,
    disabled,
    onInput,
    onChange,
    withCaption,
    value,
}) => {
    const [inputValue, setInputValue] = useState(value);
    const [invalidMessage, setInvalidMessage] = useState('');

    const handleChange = e => {
        setInputValue(e);
        onChange && onChange(e);
    };

    const handleInvalid = e => {
        e.preventDefault();
        checkValidity(e.currentTarget);
    };

    const handleKeyPress = e => {
        (preventEnter && e.key === 'Enter') && e.preventDefault();
    };

    const checkValidity = el => {
        const checkTypes = [
            'patternMismatch',
            'rangeOverflow',
            'rangeUnderflow',
            'tooLong',
            'tooShort',
            'typeMismatch',
            'valueMissing'
        ];
        let errorMessage = '';
        const { validity } = el;
        const { valid } = validity;
        if (!valid) {
            errorMessage = I18n.t('validation_error.generalInvalid');

            checkTypes.forEach(type => {
                if (validity[type]) {
                    errorMessage = I18n.t(`validation_error.${ type }`);
                }
            });

            setInvalidMessage(errorMessage);
        }
        else {
            setInvalidMessage('');
        }
    };

    const renderErrorToolTip = () => {
        return viewMode ? null : <p className="error-message">{ invalidMessage }</p>;
    };

    const captionClass = classNames({
        caption: true,
        required: required,
    });

    const wrapperClass = classNames({
        'control-group': true,
        'view-mode': viewMode,
        'disabled': disabled,
    });

    const controlClass = classNames({
        'control-wrapper': true,
        'full-width': !withCaption,
    });

    const Wrapper = Label;

    return (
        <Wrapper className={ wrapperClass }>
            {
                withCaption ?
                    (
                        <Translate className={ captionClass } value={ caption } tag="h5" />
                    ) :
                    null
            }
            <div className={ controlClass }>
                { label ? (
                    <Label className="control-group">
                        <span>{ inputValue }</span>
                    </Label>
                ) :  (
                    <Input
                        innerRef={ innerRef }
                        type={ type }
                        name={ name }
                        autoComplete={ autoComplete }
                        placeholder={ placeholder }
                        value={ inputValue }
                        onChange={ e => handleChange(e.currentTarget.value) }
                        required={ required }
                        onKeyPress={ handleKeyPress }
                        onInvalid={ handleInvalid }
                    />
                ) }
                { renderErrorToolTip() }
            </div>
        </Wrapper>
    );
};

CustomInput.propTypes = {
    type: PropTypes.oneOf([
        TYPE_TEXT,
        TYPE_EMAIL,
    ]),
    label: PropTypes.shape(),
    preventEnter: PropTypes.bool,
    viewMode: PropTypes.bool,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    innerRef: PropTypes.shape(),
    // translation key
    caption: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.shape({
            // translation key
            name: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.node,
                PropTypes.bool
            ]),
        })),
    ]),
    onInput: PropTypes.func,
    onChange: PropTypes.func,
    withCaption: PropTypes.bool,
    autoComplete: PropTypes.string,
};

CustomInput.defaultProps = {
    type: TYPE_TEXT,
    label: undefined,
    autoComplete: 'off',
    viewMode: false,
    preventEnter: true,
    placeholder: '',
    name: undefined,
    innerRef: undefined,
    caption: '',
    required: false,
    disabled: false,
    onInput: () => { },
    onChange: () => { },
    withCaption: true,
    value: '',
};

export default CustomInput;
