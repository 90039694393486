import { createStore as reduxCreateStore, compose } from 'redux';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import { getTranslations, defaultLanguage } from '../../locale';

const store = (reducers, appliedMiddleware) => {
    const translations = getTranslations();
    const composeEnhancers = (
        process.env.REACT_APP_ENV === 'innov' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ) || compose;
    const store = reduxCreateStore(reducers, composeEnhancers(appliedMiddleware));
    syncTranslationWithStore(store);
    store.dispatch(loadTranslations(translations));
    // localStore, chrome/firefox/all browsers
    const browserLanguage = localStorage.getItem('locale') || navigator.language;
    const checkedLanguage = translations[browserLanguage] ? browserLanguage : defaultLanguage;
    store.dispatch(setLocale(checkedLanguage));
    return store;
};

export default store;
