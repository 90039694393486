import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import { convertDuration } from '../../helpers/utils';

import './duration.scss';

const Duration = ({
    value,
}) => {
    const { hr, min, sec } = convertDuration(value);
    return (
        <label className="duration">
            {
                hr ? (
                    <Translate
                        value="duration-hr"
                        hr={ hr }
                    />
                ) : null
            }
            {
                min ? (
                    <Translate
                        value="duration-min"
                        min={ min }
                    />
                ) : null
            }
            {
                sec === 0 || sec ? (
                    <Translate
                        value="duration-sec"
                        sec={ sec }
                    />
                ) : null
            }
        </label>

    );
};

Duration.propTypes = {
    value: PropTypes.number,
};
Duration.defaultProps = {
    value: undefined,
};

export default Duration;