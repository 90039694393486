import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import ActionHeader from '../../components/ActionHeader';
import { getDefaultPageSize } from '../../components/Pagination/PageSizeSelect';
import List from './List';
import {
    updateDocumentTitle,
    fetchGroup,
    clearGroup,
} from '../../actions';
import './group.scss';

const Group = () => {
    const dispatch = useDispatch();
    const [selectedIdList, setSelectedIdList] = useState([]);
    const [pageSize, setPageSize] = useState(getDefaultPageSize().value);
    const [pageIndex, setPageIndex] = useState(1);
    const [dataSort, setDataSort] = useState();
    const { groupList } = useSelector(state => state.group);
    // eslint-disable-next-line no-unused-vars
    const { locale } = useSelector(state => state.i18n);

    useEffect(() => {
        return () => {
            dispatch(clearGroup());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(updateDocumentTitle(I18n.t('group.document_title')));
        dispatch(fetchGroup({ sort: dataSort }));
    }, [dataSort, dispatch, locale]);

    const handleSelect = list => {
        setSelectedIdList(list);
    };

    const handleUnchecked = () => {
        setSelectedIdList([]);
    };

    const handleChange = ({
        page = pageIndex,
        size = pageSize,
        sort = dataSort
    }) => {
        setDataSort(sort);
        setPageIndex(page);
        setPageSize(size);
    };

    const getGroupList = () => {
        handleUnchecked();
        dispatch(fetchGroup({ sort: dataSort }));
    };

    return (
        <div className="group-list app-feature-wrapper">
            <ActionHeader title={ I18n.t('group.document_title') } />
            <hr />
            <List
                list={ groupList ? groupList.slice((pageIndex - 1) * pageSize,
                    (pageIndex) * pageSize) : [] }
                onAction={ getGroupList }
                pageCount={ groupList ? Math.ceil(groupList.length / pageSize) : 1 }
                pageIndex={ pageIndex }
                pageSize={ pageSize }
                onUncheckedAll={ handleUnchecked }
                onSelect={ handleSelect }
                onChange={ handleChange }
                selectedIdList={ selectedIdList }
            />
        </div>
    );
};

export default Group;