import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import BaseTable, { AutoResizer } from 'react-base-table';
import ListHeader from './ListHeader';
import ListRow from './ListRow';
import CheckboxCell from './CheckboxCell';
import EmptyListBlocker from '../../components/EmptyListBlocker';
import classNames from 'classnames';

import 'react-base-table/styles.css';
import './list-view.scss';

const Table = props => <BaseTable width={ 720 } height={ 400 } { ...props } />;

const ListView = ({
    list,
    dataKey,
    header,
    selectable,
    defaultSelect,
    onSelect,
    onSort,
    renderListRow,
    fixed,
    isInitailResult,
    className,
}) => {

    const tableEl = useRef();
    const [sortBy, setSortBy] = useState(undefined);

    const columns = header.map(({
        name,
        value,
        width,
        sortable,
        hide,
        key,
    }, i) => ({
        dataKey: name,
        key: name,
        title: I18n.t(value),
        hidden: hide,
        width,
        sortable,
    }));

    const handleSelectAll = checked => {
        let result = [];
        if (checked) {
            result = list.map(item => item[dataKey]);
        }

        return onSelect(result, true);
    };

    const handleColumnSort = ({ field, order }) => {
        setSortBy({
            key: field,
            order,
        });

        onSort({
            field,
            order,
        });
    };
    const style = classNames({
        'list-view': true,
        [className]: className,
    });

    return (
        ( isInitailResult || list.length ) ? (
            <div ref={ tableEl } className={ style }>
                <AutoResizer>
                    { ({ width, height }) => {
                        let updateColumns = [...columns];
                        let totWidth = columns.reduce((w, { width }) => w += width, 0);
                        let defaultWidth = totWidth;

                        if (fixed) {
                            defaultWidth = totWidth;
                        }
                        else {
                            // recalculate width for each column by the ratio to total width
                            updateColumns = columns.map(column => {
                                const ratio = column.width / totWidth;
                                const newWidth = width * ratio;

                                return {
                                    ...column,
                                    width: newWidth,
                                };
                            });

                            defaultWidth = width;
                        }
                        return (
                            <Table
                                className="table table-striped"
                                headerClassName="header"
                                headerRenderer={
                                    item => {
                                        const { columns } = item;

                                        return (
                                            <ListHeader
                                                onSort={ handleColumnSort }
                                                onSelectAll={ handleSelectAll }
                                                items={ columns }
                                                selectable={ selectable }
                                                selected={
                                                    defaultSelect.length > 0 && defaultSelect.length === list.length
                                                }
                                            />
                                        );
                                    }
                                }
                                rowClassName={
                                    ({ rowIndex }) => `list-row row-${ rowIndex % 2 === 0 ? 'odd' : 'even' }`
                                }
                                rowRenderer={
                                    item => {
                                        const { columns, rowData } = item;
                                        const checkbox = (
                                            selectable && (
                                                <CheckboxCell
                                                    key={ `checkbox-${ rowData.id?.toString() }` }
                                                    onSelect={ () => onSelect([rowData.id]) }
                                                    selected={ defaultSelect.indexOf(rowData.id) > -1 }
                                                />
                                            )
                                        );
                                        const dataColumns = renderListRow ?
                                            renderListRow({ columns, rowData }) : (
                                                <ListRow
                                                    key={ dataKey }
                                                    columns={ columns }
                                                    data={ rowData }
                                                    dataKey={ dataKey }
                                                />
                                            );
                                        const row = [
                                            checkbox,
                                            dataColumns
                                        ];

                                        return row;
                                    }
                                }
                                width={ defaultWidth }
                                columns={ updateColumns }
                                height={ height }
                                data={ list.map(item => {
                                    return {
                                        parentId: null,
                                        id: item[dataKey],
                                        ...item,
                                    };
                                }) }
                                sortBy={ sortBy }
                                fixed={ fixed }
                            />
                        );
                    } }
                </AutoResizer>
            </div>
        ) :  <EmptyListBlocker />
    );
};

ListView.propTypes = {
    header: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element,
        ]),
        sortable: PropTypes.bool,
        hide: PropTypes.bool,
    })),
    list: PropTypes.arrayOf(PropTypes.shape({})),
    dataKey: PropTypes.string.isRequired,
    selectable: PropTypes.bool,
    renderListRow: PropTypes.func,
    onSelect: PropTypes.func,
    onSort: PropTypes.func,
    defaultSelect: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    ),
    fixed: PropTypes.bool,
    isInitailResult: PropTypes.bool,
    className: PropTypes.string,
};

ListView.defaultProps = {
    header: [],
    list: [],
    renderListRow: undefined,
    selectable: false,
    onSelect: undefined,
    onSort: undefined,
    defaultSelect: [],
    fixed: false,
    isInitailResult: true,
    className: undefined,
};

export default ListView;