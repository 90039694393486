import {
    PAYMENT_STATUS_SUCCESS,
    PAYMENT_STATUS_FAIL,
    PAYMENT_STATUS_UNPAID,
    PAYMENT_STATUS_PENDING,

    CORPORATE_TYPE_BASIC,
    CORPORATE_TYPE_VIP,

} from '../../../constants/trip';

import {
    TRIP_ACTIVITY,
} from '../../../constants/routes';

import Routes from './routes';

const paymentStatus = {
    [PAYMENT_STATUS_SUCCESS]: 'Complete',
    [PAYMENT_STATUS_FAIL]: 'Failed',
    [PAYMENT_STATUS_UNPAID]: 'Unpaid',
    [PAYMENT_STATUS_PENDING]: 'In Process',
};
const corporateType = {
    [CORPORATE_TYPE_BASIC]: 'Business Account',
    [CORPORATE_TYPE_VIP]: 'Business Premium',
};

const lang = {
    'document_title': Routes[TRIP_ACTIVITY],
    'start_time': 'Start Time',
    'end_time': 'End Time',
    'subtotal': 'Subtotal',
    'allowance': 'Allowance',
    'corporate_type': 'Plan',
    'corporate_type_map': corporateType,
    'payment_status_map': paymentStatus,
    'refund': 'Refunded Amount',
    'distance': 'Total Distance(km)'
};

export default lang;