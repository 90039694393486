import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';
import Datetime from '../Datetime';
const UpdateTime = ({ time }) =>
    time ?
        (
            <p className="last-updated">
                <Translate value="last_udpated" />
                <span>: </span>
                <Datetime className="profile-item" time={ time } />
            </p>
        ) : null;

UpdateTime.propTypes = {
    time: PropTypes.node,
};

UpdateTime.defaultProps = {
    time: undefined,
};

export default UpdateTime;