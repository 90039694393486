import { createBusinessApiInstanceWithToken } from '../util';
const group = {
    fetchGroup: (params = {}) => {
        return createBusinessApiInstanceWithToken().get('/groups', { params });
    },

    patchGroup: (id, params = {}) => {
        return createBusinessApiInstanceWithToken().patch(`/groups/${id}`, params);
    },

    createGroup: (params = {}) => {
        return createBusinessApiInstanceWithToken().post('/groups', params);
    },

    deleteGroup: (deleteArray = []) => {
        return createBusinessApiInstanceWithToken().delete('/groups', { data: { group_ids: deleteArray } });
    },

};

export default group;
