import React from 'react';
import moment from 'moment';
import { Translate } from 'react-redux-i18n';

import './period.scss';

const Period = ({ fromTime, toTime }) => {
    let el = null;

    if (fromTime && toTime) {
        el = (
            <div className="period">
                <Translate className="period-title" value="record_periods" />
                <span>{ moment(fromTime).format('YYYY-MM-DD') }</span>
                <span className="divider">-</span>
                <span>{ moment(toTime).format('YYYY-MM-DD') }</span>
            </div>
        );
    }

    return el;
};
export default Period;