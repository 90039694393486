import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import Avatar from '../Avatar';
import account from '../../helpers/account';
import history from '../../common/history';
import iconMenu from '../../assets/icon-menu.svg';
import { toggleMenu, fetchCompanyAuth } from '../../actions';
import { ROOT } from '../../constants/routes';

import './main-header.scss';

const Header = () => {
    const dispatch = useDispatch();
    const { companyName } = useSelector(state => state.account);
    useEffect(() => {
        if (!account.exists()) {
            history.push(ROOT);
        }
    }, [dispatch]);

    useEffect(() => {
        if (account.exists()) {
            dispatch(fetchCompanyAuth());
        }
    }, [companyName, dispatch]);

    const handleMenuClick = () => {
        dispatch(toggleMenu());
    };

    return (
        <header className="main-header">
            <section className="logo-area">
                <Button className="btn-menu" onClick={ handleMenuClick }>
                    <img src={ iconMenu } alt="" />
                </Button>
                <span className="logo-goshare">GOSHARE</span>
                <span className="logo-prod">FOR BUSINESS</span>
            </section>
            <section className="profile-sec">
                <span className="company-name">{ companyName }</span>
                <Avatar />
            </section>
        </header>
    );
};

export default Header;