import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import Cell from './Cell';
import { SORT_ORDER } from './constants';
import { useState } from 'react';

const ListHeader = (props) => {
    const { selectable, selected, items, onSelectAll, onSort } = props;
    const [sort, setSort] = useState({});
    const visibleHeader = items.filter((field) => !field.hide);

    const handleSelectAll = () => {
        onSelectAll(!selected);
    };

    const handleSort = ({ field, order }) => e => {
        e.stopPropagation();
        setSort({ [field]: order });
        onSort({ field, order });
    };

    const nextSort = field => {
        let nextOrder = SORT_ORDER.ASC;

        switch (sort[field]) {
        case SORT_ORDER.ASC:
            nextOrder = SORT_ORDER.DESC;
            break;
        case SORT_ORDER.DESC:
            nextOrder = undefined;
            break;
        default:
        }

        let nextSort = {};

        if (nextOrder) {
            nextSort = { field, order: nextOrder };
        };

        return nextSort;
    };
    return (
        <React.Fragment>
            { selectable ?
                (
                    <Cell name="checkbox" onClick={ handleSelectAll }>
                        <Input type="checkbox" checked={ selected } onChange={ () => { } } />
                    </Cell>
                ) : null
            }
            { visibleHeader.map(({ key, title, sortable, width }) => {
                const order = sort[key];
                const thClassName = classNames({
                    sortable,
                });
                const sortClassName = classNames({
                    'sorting': true,
                    'asc': order === SORT_ORDER.ASC,
                    'desc': order === SORT_ORDER.DESC,
                });
                return (
                    <Cell
                        key={ key }
                        name={ key }
                        className={ thClassName }
                        onClick={ sortable ? handleSort(nextSort(key)) : null }
                        style={ { width } }
                    >
                        <span>{ title }</span>
                        { sortable ? <span className={ sortClassName } /> : null }
                    </Cell>
                );
            }) }
        </React.Fragment>
    );
};

ListHeader.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onSelectAll: PropTypes.func,
    onSort: PropTypes.func,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
};

ListHeader.defaultProps = {
    onSelectAll: undefined,
    onSort: undefined,
    selectable: false,
    selected: false,
};

export default ListHeader;