import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import storeCreater from './util/store-creater';
import asyncHandler from './util/async-handler';
import generalReducer from './general';
import accountReducer from './account';
import systenReducer from './system';
import userReducer from './user';
import billingReducer from './billing';
import tripReducer from './trip';
import groupReducer from './group';
import fileReducer from './file';

export default combineReducers({
    asyncHandler,
    account: accountReducer,
    user: userReducer,
    group: groupReducer,
    general: generalReducer,
    system: systenReducer,
    billing: billingReducer,
    trip: tripReducer,
    file: fileReducer,
    i18n: i18nReducer,
});

export const createStore = storeCreater;