export const headerConfig = [{
    name: 'name',
    value: 'user.name',
    sortable: true,
    width: 246,
}, {
    name: 'verification_status',
    value: 'user.verification_status',
    sortable: true,
    width: 250,
}, {
    name: 'email',
    value: 'email',
    sortable: true,
    width: 400,
}, {
    name: 'group_name',
    value: 'user.group',
    sortable: true,
    width: 200,
}, ];
