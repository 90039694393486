export const headerConfig = [{
    name: 'name',
    value: 'group.group',
    sortable: true,
    width: 246,
}, {
    name: 'description',
    value: 'group.description',
    sortable: false,
    width: 326,
}, {
    name: 'count',
    value: 'group.count',
    sortable: true,
    width: 200,
} ];
