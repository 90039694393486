export const AUTH_VIEW_TRIP_ACTIVITY = 'VIEW_TRIP_ACTIVITY';
export const AUTH_VIEW_USER_LIST = 'VIEW_USER_LIST';
export const AUTH_VIEW_GROUP_LIST = 'VIEW_GROUP_LIST';
export const AUTH_VIEW_BUSINESS_PLAN = 'VIEW_BUSINESS_PLAN';
export const AUTH_EDIT_USER = 'EDIT_USER';
export const AUTH_EDIT_GROUP = 'EDIT_GROUP';

export const ADMIN = 'ADMIN';
export const USER_MANAGER = 'User_Manager';
export const OVERSEA_USER = 'Oversea_Business_User';
export const OVERSEA_ADMIN = 'Oversea_Business_Admin';

export const TAIWAN = 0;
export const OVERSEA = 1;