import {
    TOGGLE_MENU,
    TOGGLE_MENU_CATEGORY,
    CLEAR_MENU_CATEGORY_ACTIVE,
    UPDATE_DOCUMENT_TITLE,
    SHOW_PLEASE_LOGIN_DIALOG,
} from '../constants/action-type';

export const updateDocumentTitle = (title) => {
    return {
        type: UPDATE_DOCUMENT_TITLE,
        data: title
    };
};

export const toggleMenu = () => {
    return {
        type: TOGGLE_MENU,
    };
};

export const toggleMenuCategory = (category, switchStatus, activeCategory) => {
    return {
        type: TOGGLE_MENU_CATEGORY,
        data: {
            category,
            switchStatus,
            activeCategory,
        },
    };
};

export const clearMenuCategoryActive = () => {
    return {
        type: CLEAR_MENU_CATEGORY_ACTIVE,
    };
};

export const togglePleaseLoginDialog = () => {
    return {
        type: SHOW_PLEASE_LOGIN_DIALOG,
    };
};