import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { Translate } from 'react-redux-i18n';
import Form from '../Form';
import ButtonGroup from '../ButtonGroup';
import FlexGroup from '../FlexGroup';
import Modal from '../Modal';
import Blocker from '../Blocker';
import RingSpinner from '../Spinner/Ring';

import './delete-panel.scss';

const DeletePanel = ({ title, description, onClose, onSubmit, selectedIdList, list }) => {
    const [ loading, setLoading ] = useState(false);
    const filterList = list.filter(({ id }) => selectedIdList.includes(id));

    const spinnerStyle = classNames({
        'spinner-container': true,
        hide: !loading,
    });

    const handleSubmit = (file, type) => {
        const ret = onSubmit(file, type);
        if (ret instanceof Promise) {
            setLoading(true);
            ret.then(
                () => {
                    onClose();
                }
            ).finally(() => {
                setLoading(false);
            });
        }
    };

    return (
        <div className="delete-panel">
            <Modal title={ title } onClose={ onClose }>
                <FlexGroup start align-start className="delete-content">
                    <Translate value={ description } />
                    <span className="delete-user">
                        {
                            filterList.map((item, index) => {
                                return ` ${item.name} ${index !== filterList.length - 1 ? '、' : '？'} `;
                            })
                        }
                    </span>
                </FlexGroup>
                <Form className="section" onSubmit={ handleSubmit }>
                    <ButtonGroup className="modal-button-group">
                        <Button type="button" color="secondary" onClick={ onClose }>
                            <Translate value="cancel" />
                        </Button>
                        <Button color="primary">
                            <Translate value="delete" />
                        </Button>
                    </ButtonGroup>
                </Form>
            </Modal>
            <Blocker className={ spinnerStyle }>
                <RingSpinner />
            </Blocker>
        </div>
    );
};

DeletePanel.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    selectedIdList: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func,
};

DeletePanel.defaultProps = {
    title: '',
    description: '',
    onClose: () => {},
};

export default DeletePanel;