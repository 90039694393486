import React from 'react';
import PropTypes from 'prop-types';
import Cell from '../../components/ListView/Cell';

const ListRow = ({ columns, data, onClick }) => {

    const renderField = (renderData, key, width) => {
        let el = renderData[key];
        switch (key) {
        case 'name':
            el = (
                <span
                    className="name"
                    onClick={ () => onClick(renderData) }
                >
                    { renderData.name }
                </span>
            );
            break;
        case 'description':
            el = ( <span>{ renderData.description }</span> );
            break;
        case 'number':
            el = ( <span>{ renderData.count }</span> );
            break;
        default:
            el = ( <span>{ renderData[key] }</span> );
            break;
        }

        return (
            <Cell
                key={ `group-${ renderData.id }-${ key }` }
                name={ key }
                style={ { width } }
            >
                { el }
            </Cell>
        );
    };

    return columns.map(({ key, width }, i) => {
        return renderField(data, key, width);
    });
};

ListRow.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        user_name: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
    }).isRequired,
    onClick: PropTypes.func,
};

ListRow.defaultProps = {
    onClick: () => {},
};

export default ListRow;