import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { logout } from '../../actions';
import { Translate } from 'react-redux-i18n';
import Blocker from '../Blocker';
import history from '../../common/history';
import { ROOT } from '../../constants/routes';
import './avatar.scss';
import account, { IS_LOGGED_IN } from '../../helpers/account';
import Locale from '../../components/Locale';

class Avatar extends Component {
    static propTypes = {
        isLoggedIn: PropTypes.bool,
        profile: PropTypes.shape(),
        i18n: PropTypes.shape({
            locale: PropTypes.string,
            translations: PropTypes.shape(),
        }).isRequired,
    };
    static defaultProps = {
        isLoggedIn: false,
        profile: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { isLoggedIn } = this.props;
        if (isLoggedIn !== prevProps.isLoggedIn && !isLoggedIn) {
            history.push(ROOT);
        }
    }

    handleLogout = () => {
        const { dispatch } = this.props;
        this.setState({
            open: false
        }, () => {
            dispatch(logout());
        });
    };

    toggleProfileCard = () => {
        const { open } = this.state;

        this.setState({
            open: !open,
        });
    };

    renderProfileCard({ picture, name, email }) {
        const { open } = this.state;
        const panelClass = classNames({
            'collasible-panel': true,
            'show': open,
        });
        return (
            <Blocker className={ panelClass } onClick={ this.toggleProfileCard }>
                <div className="panel-body" onClick={ e => e.stopPropagation() }>
                    <img className="avatar large" src={ picture } alt={ name } />
                    <figcaption>
                        <p className="info name" title={ name }>{ name }</p>
                        <p className="info email">{ email }</p>
                    </figcaption>
                    <Locale />
                    <Button onClick={ this.handleLogout }>
                        <Translate value="logout" className="logout-text" />
                    </Button>
                </div>
            </Blocker>
        );
    }

    render() {
        const { isLoggedIn, profile } = this.props;
        const profileInfo = isLoggedIn && profile;
        let el = null;
        if (profileInfo) {
            const { picture, name } = profileInfo;
            el = (
                <div className="account-profile" onClick={ this.toggleProfileCard }>
                    <img className="avatar" src={ picture } alt={ name } />
                    { this.renderProfileCard(profile) }
                </div>
            );
        }

        return el;
    }
};

export default connect(state => {
    return ({
        isLoggedIn: account.get(IS_LOGGED_IN),
        profile: account.get(),
        i18n: state.i18n,
    });
})(Avatar);
