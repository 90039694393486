/**
 * Utilities
 */
import {
    ERROR,
    ASYNC_START,
    ASYNC_FINISHED,
} from '../constants/action-type';
import axios from 'axios';
import { I18n } from 'react-redux-i18n';
import { openSystemDialog, logout } from './index';
import account from '../helpers/account';
import { fetchNewToken } from './account';

I18n.setHandleMissingTranslation(text => '');

export const checkToken = dispatch => {
    const expires_at = account.getExpiresAt();
    let promise = Promise.resolve();
    if (account.exists()) {
        if (Date.now() > expires_at || account.getID() === '') {
            promise = Promise.resolve(dispatch(fetchNewToken()));
            promise.then((res = {}) => {
                const { data } = res;
                if (data) {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${ data.id_token }`;
                }

                return res;
            });
        }
    }
    else {
        promise = Promise.reject(new Error('Not Login Yet'));
    }

    return promise;
};

export const asyncError = err => {
    return {
        type: ERROR,
        error: err,
    };
};

export const asyncStart = () => {
    return {
        type: ASYNC_START,
    };
};

export const asyncFinished = () => {
    return {
        type: ASYNC_FINISHED,
    };
};

/**
 * Async action interface that wraps up the process.
 */
export const asyncInterface = ({ asyncAction, type, dispatch }) => {
    dispatch(asyncStart());
    return checkToken(dispatch).then(() => {
        return asyncAction();
    }).then(({ data }) => {
        dispatch(asyncFinished());
        return dispatch({
            type,
            data,
        });
    }).catch(err => {
        if (process.env.REACT_APP_ENV !== 'production') {
            console.error(err);
        }
        dispatch(asyncFinished());
        if (err.response) {
            const { code, error_message } = err.response.data;
            let errorMessage = '';

            switch (code) {
            case 2001:
            case 2007:
                errorMessage = `${error_message} ${I18n.t(`error.error_${code}`)}`;
                break;
            default:
                errorMessage = `${I18n.t(`error.error_${code}`)}` || `${I18n.t('error.error_0')} (Code: ${code})`;

            }

            if (err.response.status !== 401) {
                dispatch(openSystemDialog(
                    I18n.t('error.error'),
                    errorMessage
                ));
            }
            else {
                dispatch(logout());
            }
        }
        else if (err.request) {
            dispatch(openSystemDialog(
                I18n.t('error.error'),
                `${I18n.t('error.error_0')}`
            ));
        }
        return dispatch(asyncError(err));
    });
};
