export const ERROR_CODE_0 = 0;

// User
export const ERROR_CODE_2000 = 2000;
export const ERROR_CODE_2001 = 2001;
export const ERROR_CODE_2002 = 2002;
export const ERROR_CODE_2003 = 2003;
export const ERROR_CODE_2004 = 2004;
export const ERROR_CODE_2005 = 2005;
export const ERROR_CODE_2006 = 2006;
export const ERROR_CODE_2007 = 2007;
export const ERROR_CODE_2008 = 2008;
export const ERROR_CODE_2009 = 2009;
export const ERROR_CODE_2010 = 2010;

// Group
export const ERROR_CODE_3000 = 3000;
export const ERROR_CODE_3001 = 3001;