import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-redux-i18n';
import {
    CORPORATE_TYPE_BASIC,
    CORPORATE_TYPE_VIP,
} from '../../constants/trip';

export const getCorporareTypeOptions = () => [
    CORPORATE_TYPE_BASIC,
    CORPORATE_TYPE_VIP,
].map(
    value => ({
        text: I18n.t(`trip.corporate_type_map.${ value }`),
        value,
    })
);
const CorporateType = ({ type, className }) => {
    let el;

    switch (type) {
    case CORPORATE_TYPE_VIP:
    case CORPORATE_TYPE_BASIC:
        el = <Translate value={ `trip.corporate_type_map.${ type }` } />;
        break;
    default:
        el = <span>--</span>;
    }

    return el;
};

CorporateType.propTypes = {
    type: PropTypes.number,
    className: PropTypes.string,
};

CorporateType.defaultProps = {
    type: -1,
    className: '',
};

export default CorporateType;
